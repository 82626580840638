/* Module: S */
.latest-news, .news_n_blogs {
  margin-bottom: 10px;
  position: relative;

  &__arrows {
    position: absolute;
    right: 5px;
    top: 40px;
    z-index: 2;
    @include rwd-to($tablet) {
      top: 30px;
    }

    .icon-arrow {
      display: inline-block;
      width: 42px;
      height: 42px;
      background-color: $brand-primary-first;
      border: none;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: 10px 20px;
      z-index: 2;
      margin-top: -21px;
      &:hover{
        background-color: $brand-primary-second;
      }
      &.disactive {
        opacity: 0.5;
      }

      &-left {
        left: -52px;
        background-position: 15px center;
      }

      &-right {
        right: -52px;
        background-position: 18px center;
      }
    }
  }

  .news_list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    margin-top:20px;
  }

  h2 {
    margin-left: 5px;
    margin-right: 5px;
  }

  .newswide, .news {
    border-bottom: 10px solid $white;
    border-radius: 3px;
    background-clip: padding-box;
    border: 5px solid transparent;
    padding-left: 0px !important;
    padding-right: 0px !important;
    //padding-bottom:40px;

    .img-wrapper {
      position: relative;
      padding-bottom: 230px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
    }

    .news-wrapper {
      padding: 20px;

      .titlewrapper {
        height: 80px;
        overflow: hidden;
        margin-bottom: 15px;
        a {
          text-decoration: none;
        }
      }
    }

    h4 {
      font-size: 20px;
      margin-top: 0;
      padding-right: 30px;
      vertical-align: middle;
      display:block;
      font-family:SinkinSans;
      font-weight:100;
      font-size: 18px;
      line-height:1.3;
      
      a {
        //color: #333333;
      }

      & span {
        color: $brand-primary;
      }
    }

    p {
      font-weight: 300;
      margin-top: 0px;
    }
  }
  &.listing_page {
    .newswide, .news {
      p {
        margin-bottom: 0px;
      }
      .news-wrapper {
        // min-height: 315px;
        .titlewrapper {
          //min-height: 100px;
          height: auto;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  //&.listing_page {
  //  .news {
  //    p {
  //      margin-bottom: 0px;
  //    }
  //    .news-wrapper {
  //      min-height: 315px;
  //      .titlewrapper {
  //        min-height: 100px;
  //      }
  //    }
  //  }
  //}
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.latest-news {
  h3 {
    a {
      text-decoration: none;
      .loretto & {
        color: $brand-primary !important;
      }
    }
  }
}

.home .main .latest-news h2 {
  font-size: 28px !important;
}

.goog-te-gadget img {
  margin-bottom: 0;
}

.newswide {
  background-color: $light-bg;

  .news-wrapper {
    >a {
      position: absolute;
      //bottom: 20px;
      bottom:auto;
      padding-top:5px;
      text-decoration: none;
    }
    .titlewrapper {
      border-bottom: 1px solid $light-border;
      display: table;
      width: 100%;
    }
  }
}

.news {
  background-color: #F2F2F2;

  .news-wrapper {
    .titlewrapper {
      border-bottom: 1px solid #BFBFBF;
      display: table;
      width: 100%;
      a {
        text-decoration: none;
      }
    }
  }
}

@media screen and (min-width: 991px) {
  .latest-news {
    &__arrows {
      display: none;
    }
  }
}

@media screen and (max-width: 991px) {
  .news_list {
    .homepage:not(.active) {
      display: none;
    }

    .homepage {
      width: 100%;
    }
  }
}
