/* Module: S */
.mp-menu {
  display: none;
}

@media screen and (max-width: 990px) {

  .scroller,
  .scroller-inner {
    position: relative;
  }

  .scroller-inner {
    overflow-x: hidden;
  }

  .menu-container {
    position: relative;
    overflow: hidden;
  }

  .menu-trigger {
    position: relative;
    padding-left: 60px;
    font-size: 16px;
  }

  .menu-trigger:before {
    position: absolute;
    top: 2px;
    left: 0;
    width: 40px;
    height: 6px;
    background: #fff;
    box-shadow: 0 6px #34495e, 0 12px #fff, 0 18px #34495e, 0 24px #fff;
    content: '';
  }

  html, body, .menu-container, .scroller {
    height: 100%;
    overflow-x: hidden;
  }

  .scroller {
    overflow-y: scroll;
  }

  .mp-pusher {
    position: relative;
    left: 0;
    height: 100%;
  }

  .mp-menu {
    display: block;
    position: absolute; /* we can't use fixed here :( */
    top: 0;
    left: 0;
    z-index: 1;
    width: 300px;
    height: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  .mp-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $brand-primary-first;
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  .mp-level[data-level="2"] {
    background: #e4e4e4;

    a, h2 {
      color: $black;
    }

    a {
      font-weight: 400;
      box-shadow: inset 0 -1px #b6b6b6;
    }

    li:first-child > a {
      box-shadow: inset 0 -1px #b6b6b6, inset 0 1px #b6b6b6;
    }

    .gha-cube &, .gha-wlhp &, .dunedin &, .loretto & {
      a {
        font-weight: 400;
        box-shadow: inset 0 -1px #b6b6b6;
      }

      li:first-child > a {
        box-shadow: inset 0 -1px #b6b6b6, inset 0 1px #b6b6b6;
      }
    }

  }

  .mp-level[data-level="3"] {
    background: #fff4f3;
    .loretto & {
      background-color: $loretto-light-bg;
      a {
        font-weight: 400;
        box-shadow: inset 0 -1px #d5ebf2;
      }

      li:first-child > a {
        box-shadow: inset 0 -1px #d5ebf2, inset 0 1px #d5ebf2;
      }
    }
    a {
      box-shadow: inset 0 -1px #ebc3c2;
    }

    li:first-child > a {
      box-shadow: inset 0 -1px #ebc3c2, inset 0 1px #ebc3c2;
    }

    .gha-cube &, .gha-wlhp & {
      background-color: #faf2f4;

      a {
        box-shadow: inset 0 -1px #f1d9e1;
      }

      li:first-child > a {
        box-shadow: inset 0 -1px #f1d9e1, inset 0 1px #f1d9e1;
      }
    }
  }

  /* overlays for pusher and for level that gets covered */
  .mp-pusher::after,
  .mp-level::after,
  .mp-level::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0;
  }

  .mp-pusher::after,
  .mp-level::after {
    background: rgba(0, 0, 0, 0.3);
    -webkit-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    -moz-transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
    transition: opacity 0.3s, width 0.1s 0.3s, height 0.1s 0.3s;
  }

  .mp-level::after {
    z-index: -1;
  }

  .mp-pusher.mp-pushed::after,
  .mp-level.mp-level-overlay::after {
    width: 100%;
    height: 100%;
    opacity: 1;
    -webkit-transition: opacity 0.3s;
    -moz-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }

  .mp-level.mp-level-overlay {
    cursor: pointer;
  }

  .mp-level.mp-level-overlay.mp-level::before {
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;
  }

  .mp-pusher,
  .mp-level {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
  }

  /* overlap */
  .mp-overlap .mp-level.mp-level-open {
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.2);
    -webkit-transform: translate3d(-40px, 0, 0);
    -moz-transform: translate3d(-40px, 0, 0);
    transform: translate3d(-40px, 0, 0);
  }

  /* First level */
  .mp-menu > .mp-level,
  .mp-menu > .mp-level.mp-level-open,
  .mp-menu.mp-overlap > .mp-level,
  .mp-menu.mp-overlap > .mp-level.mp-level-open {
    box-shadow: none;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  /* cover */
  .mp-cover .mp-level.mp-level-open {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    -webkit-transform: translate3d(-100%, 0, 0);
    -moz-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  /* content style */
  .mp-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .mp-menu h2 {
    margin: 0;
    padding: 1em 1em;
    color: $white;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
    font-weight: 500;
    font-size: 18px;
    line-height: 1.4rem;
  }

  .mp-menu.mp-overlap h2::before {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    font-size: 75%;
    line-height: 1.8;
    opacity: 0;
    -webkit-transition: opacity 0.3s, -webkit-transform 0.1s 0.3s;
    -moz-transition: opacity 0.3s, -moz-transform 0.1s 0.3s;
    transition: opacity 0.3s, transform 0.1s 0.3s;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  .mp-menu.mp-cover h2 {
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    font-size: 16px;
  }

  .mp-overlap .mp-level.mp-level-overlay > h2::before {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    -moz-transition: -moz-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    transform: translateX(0);
  }

  .mp-menu ul li > a {
    display: block;
    padding: 0.7em 1em 0.7em 1.4em;
    outline: none;
    box-shadow: inset 0 -1px #009297;
    font-weight: 200;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.1);
    font-size: 16px;
    color: $white;
    -webkit-transition: background 0.3s, box-shadow 0.3s;
    -moz-transition: background 0.3s, box-shadow 0.3s;
    transition: background 0.3s, box-shadow 0.3s;

    &:hover,
    &:focus {
      color: $white;
    }

  }

  .mp-menu ul li::before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0, 0, 0, 0.2);
    line-height: 3.5;
  }

  .mp-level > ul > li:first-child > a {
    box-shadow: inset 0 -1px #009297, inset 0 1px #009297;
  }

  .mp-menu ul li a:hover,
  .mp-level > ul > li:first-child > a:hover {
    background: rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  }

  .mp-menu .mp-level.mp-level-overlay > ul > li > a,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0);
  }

  .mp-level > ul > li:first-child > a:hover,
  .mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px rgba(0, 0, 0, 0), inset 0 1px rgba(0, 0, 0, 0);
  }
  /* seems like Chrome 34.0.1847.131 needs the second shadow otherwise the transition breaks */

  .mp-back {
    background: rgba(0, 0, 0, 0.1);
    outline: none;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 16px;
    padding: 1em;
    position: relative;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.1);
    -webkit-transition: background 0.3s;
    -moz-transition: background 0.3s;
    transition: background 0.3s;
  }

  .mp-back::after {
    font-family: 'linecons';
    position: absolute;
    content: "\e037";
    right: 10px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.3);
  }

  .mp-menu .mp-level.mp-level-overlay > .mp-back,
  .mp-menu .mp-level.mp-level-overlay > .mp-back::after {
    background: transparent;
    box-shadow: none;
    color: transparent;
  }

  /* Fallback example for browsers that don't support 3D transforms (and no JS fallback) */
  /* We'll show the first level only */

  .no-csstransforms3d .mp-menu .mp-level,
  .no-js .mp-menu .mp-level {
    display: none;
  }

  .no-csstransforms3d .mp-menu > .mp-level,
  .no-js .mp-menu > .mp-level {
    display: block;
  }
}
