/* Module: S */
.related-content {

  h2 {
    @extend .button;
    width: 100%;
    padding-left: 20px;
    &:hover {
      background-color: $brand-primary-second;
    }

    &:before {
      display: none;
    }
  }
  // h2 {
  //   background-color: $brand-primary-second;
  //   border-radius: 2px 0 0 2px;
  //   color: #fff;
  //   font-size: 20px;
  //   font-weight: lighter;
  //   padding: 0.5em 1em;
  //   position: relative;
  //   display: inline-block;
  //   border-right: 12px solid $brand-primary-second;
  //   width: calc(100% - 54px);
  //   margin-bottom: 1em;
  //   border-top-right-radius: 3px;
  //   border-bottom-right-radius: 3px;



  //   &:before {
  //     content: '';
  //     position: absolute;
  //     right: -42px;
  //     top: 0;
  //     border-style: solid;
  //     border-color: $brand-primary-second transparent transparent $brand-primary-second;
  //     border-width: 21px;
  //     z-index: 1;
  //     box-shadow: -3px 0 0 0 $brand-primary-second;
  //     left: auto;
  //     background-color: transparent;
  //   }

  //   &:after {
  //     border-radius: 0 2px 0 0;
  //     content: '';
  //     position: absolute;
  //     right: -54px;
  //     top: 0;
  //     border-style: solid;
  //     border-color: $brand-primary-second transparent transparent $brand-primary-second;
  //     border-width: 21px;
  //     box-shadow: -3px 0 0 0 $brand-primary-second;
  //   }
  //}

  li {
    margin-bottom: 1em;
  }

  .download-link{

  }
}

// .related-content {
//   h2 {
//     background-color: #e9624f;
//     border-radius: 0 2px 0 0;
//     color: #fff;
//     font-size: 20px;
//     font-weight: lighter;
//     padding: 0.5em 1em;
//     position: relative;
//     -webkit-clip-path: polygon(0 0, 100% 0, calc(100% - 30px) 100%, 0 100%);
//
//     &:after {
//       background-color: $brand-primary;
//       border-radius: 0 2px 0 0;
//       content: '';
//       height: 100%;
//       position: absolute;
//       right: 0;
//       top: 0;
//       width: 40px;
//       -webkit-clip-path: polygon(calc(100% - 10px) 0, 100% 0, 100% 100%, 0 100%);
//     }
//   }
// }
