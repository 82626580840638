/* Module: S */
.form {
  .intro__form {
    @extend .intro;
    font-weight: 300;
    font-size: 22px;
  }
  &__break {
    border-top: 1px solid #ccc;
    padding-bottom: 20px;
  }
  &__body {
    background-color: #f8f8f8;
    border-bottom: 3px solid #e4e4e4;
    margin-bottom: 1em;
    padding: 20px;
    ul {
      padding-left: 0;
      list-style: none;
    }
    li {
      display: inline-block
    }
    fieldset {
      border: none;
      label {
        font-size: 16px;
        font-weight: 300;
        color: $font-grey;
        margin-bottom:0.5em;
        line-height: 22px;
      }
      legend {
        font-size: 16px;
        font-weight: 700;
        color: $font-grey;
        margin-bottom: 0;
      }
      .form__questions,
      .form__subquestions {
        //display:
        display:block !important; //from override css
      }
      select, input[type=text], textarea, input[type=email] {
        width: 100%;
        @include sq-border-radius(0px);
        height: 40px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        font-size: 18px;
        color: #999999;
        padding-left: 20px;
        border: 1px solid #999999;
        margin-bottom: 30px;
        padding-right: 60px;
      }
      select::-ms-expand {
        display: none;
      }
      select {
        cursor: pointer;
        background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        -webkit-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        -moz-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
        background: url("mysource_files/dropdown.svg") right center no-repeat,
        linear-gradient(to left, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
        &:hover, &:focus {
          background: #ffffff url(mysource_files/dropdown.png) right center/40px 40px no-repeat;
          background: url(mysource_files/dropdown.svg) right center no-repeat, -webkit-linear-gradient(right, $brand-primary-first 40px, white 40px);
          background: url(mysource_files/dropdown.svg) right center no-repeat, -moz-linear-gradient(right, $brand-primary-first 40px, white 40px);
          background: url(mysource_files/dropdown.svg) right center no-repeat, linear-gradient(to left, $brand-primary-first 40px, white 40px);
          //.gha-cube & {
          //  background: #ffffff url("mysource_files/gha-cube-dropdown.png") right center / 40px 40px no-repeat;
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -webkit-linear-gradient(right, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -moz-linear-gradient(right, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  linear-gradient(to left, $cube-main-color-green 40px, rgba(255, 255, 255, 1) 40px);
          //}
          //.gha-wlhp & {
          //  background: #ffffff url("mysource_files/gha-wlhp-dropdown.png") right center / 40px 40px no-repeat;
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -webkit-linear-gradient(right, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -moz-linear-gradient(right, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  linear-gradient(to left, rgba(0, 76, 151, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //}
          //.dunedin & {
          //  background: #ffffff url("mysource_files/dunedin-dropdown.png") right center / 40px 40px no-repeat;
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -webkit-linear-gradient(right, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  -moz-linear-gradient(right, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //  background: url("mysource_files/dropdown.svg") right center no-repeat,
          //  linear-gradient(to left, rgba(0, 47, 108, 1) 40px, rgba(255, 255, 255, 1) 40px);
          //}
          .loretto & {
            background: #ffffff url(mysource_files/dropdown.png) right center/40px 40px no-repeat;
            background: url(mysource_files/dropdown.svg) right center no-repeat, -webkit-linear-gradient(right, $brand-primary-first 40px, white 40px);
            background: url(mysource_files/dropdown.svg) right center no-repeat, -moz-linear-gradient(right, $brand-primary-first 40px, white 40px);
            background: url(mysource_files/dropdown.svg) right center no-repeat, linear-gradient(to left, $brand-primary-first 40px, white 40px);
          }
        }
        &:focus::-ms-value {
          background-color: #ffffff;
          color: black;
        }
      }
      .form__dropdown {
        position: relative;
        &-box {
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 40px;
          background: #666666 url("mysource_files/dropdown.svg") 100% 50%/40px 40px no-repeat;
        }
        select:hover + .form__dropdown-box,
        select.is-selected + .form__dropdown-box {
          background: red url("mysource_files/dropdown.svg") 100% 50%/40px 40px no-repeat;
          transition: background-color 0.3s;
          .gha-cube & {
            background-color: #a50034;
          }
          .gha-wlhp & {
            background-color: $wlhp-main-color-blue;
          }
          .dunedin & {
            background-color: $dunedin-main-color-green;
          }
          .loretto & {
            background-color: $loretto-main-color-darkblue;
          }
        }
      }
      input {
        background: $white;
      }
      input[type=radio] {
        position: relative;
        @include sq-border-radius(10px);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: $white;
        width: 20px;
        height: 20px;
        border: 1px solid #999999;
        vertical-align: -20%;
        box-sizing: border-box;
        display: inline-block;
        margin-top: 0;
        & ~ label {
          font-weight: 200;
          margin-right: 20px;
          display: inline;
        }
        &:after {
          position: absolute;
          width: 10px;
          top: 4px;
          left: 4px;
          height: 10px;
          content: " ";
          background-color: transparent;
          @include sq-border-radius(5px);
        }
        &:checked:after {
          position: absolute;
          width: 10px;
          top: 4px;
          left: 4px;
          height: 10px;
          content: " ";
          background-color: $brand-primary-first;
          @include sq-border-radius(5px);

          //.gha-cube & {
          //  background-color: $cube-main-color-green;
          //}
          //.gha-wlhp & {
          //  background-color: $wlhp-main-color-blue;
          //}
          //.dunedin & {
          //  background-color: $dunedin-main-color-blue;
          //}
          .loretto & {
            background-color: $brand-primary-first;
          }
        }
      }
      input::-ms-check {
        color: $brand-primary-first;
        //.gha-cube & {
        //  color: $cube-main-color-green;
        //}
        //.gha-wlhp & {
        //  color: $wlhp-main-color-blue;
        //}
        //.dunedin & {
        //  color: $dunedin-main-color-blue;
        //}
        .loretto & {
          color: $brand-primary-first;
        }
      }
      textarea {
        height: 200px;
        padding: 10px 20px;
      }
      .error {
        color: $brand-primary-first;
        .loretto & {
          color: $brand-primary-first;
        }
      }
      .error:required:invalid {
        border: 2px solid $black;
        &::-webkit-input-placeholder {
          color: $brand-primary-first;
          .loretto & {
            color: $brand-primary-first;
          }
        }
      }
      .button__form {
        @extend .button;
        a {
          color: $white;
          font-weight: 200;
          padding: 0 15px;
        }
        &:focus {
          background-color: $brand-primary-first;
          .loretto & {
            background-color: $brand-primary-first;
          }
        }
      }
      p {
        margin: 18px;
      }
      .columnlist {
        ul {
          li {
            display: list-item;
            label {
              margin-bottom: 5px;
            }
            &:last-child {
              label {
                margin-bottom: 30px;
              }
            }
          }
        }
      }

      .sq-form-question-answer {
        label {
          font-weight: 400;
        }

        input[type="radio"].sq-form-field {
          margin-left: 0;
          & + label {
            margin-right: 20px;
          }
        }
      }
    }

    .sq-form-unattached {
      > .sq-form-question-text {
        margin: 0 2px;
        padding: 0 0.625em;
      }
    }
  }
}

.sq-form-required-field {
  display: inline-block;
}

.radiolist {
  margin-bottom:20px;
  ul {
    li {
      display:block;
      line-height:0;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

#form__js-mainSelect:nth-child(2) {
  margin-bottom:20px;
}