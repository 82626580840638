/* Module: S */
.standard-page-content {
  margin-top:0.67em;
  .intro {
    //font-family: FSMe;
    font-weight: 200;
    line-height: 31px;
  }
  h3 {
    padding-top:1em;
  }
  .latest-news {
    h3 {
      padding-top: 0;
    }
  }
}