/* Module: S */
.get-in-touch {
  display: inline-block;
  padding: 10px;
  border-bottom-right-radius: 22px;
  margin: 30px 0;
  &:hover {
    p {
      text-decoration: none;
    }
    h2 {
      text-decoration: underline;
      -moz-text-decoration-color: #fff; /* Code for Firefox */
      text-decoration-color: #fff;
    }
  }
  &.green {
    background: $brand-primary;
    width: 100%;
  }
  &.blue {
    background: $brand-primary-first;
  }
  &:before {
    content: '';
    display: table;
    clear: both;
  }

  svg {
    float: left;
    display: block;
    margin: 40px 15px;
  }

  &-content {
    float: left;
    width: calc(100% - 105px);
    h2 {
      color: white;
      font-weight: 200;
      line-height: 44px;
      font-size: 30px !important;
      padding-top: 0 !important;
      &::before {
        width: 0 !important;
        height: 0 !important;
      }
    }
    p {
      color: $font-grey-dark;
      strong {
        color: $white !important;
      }
    }
  }
  p {
    color: $white;
  }
}

@include rwd-to(992) {
  .get-in-touch {
    svg {
      float: none;
      margin: 20px auto 10px;
      display: block;
    }
    h2 {
      font-size: 28px !important;
    }
    h2:before {
      content: none;
    }
    &-content {
      float: none;
      width: initial;
      text-align: center;
      p {
        font-weight: bold;
      }
    }
  }
}