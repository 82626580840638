/* Module: S */
.promo-home2 {
  position: relative;
  overflow: hidden;
  padding: 30px 0 80px 0;
  margin: 40px 0;
  @include rwd-to(991) {
    padding-top: 10px;
    padding-bottom: 40px;
    background: $brand-primary;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -35%;
    left: 0;
    width: 40%;
    height: 170%;
    background: $brand-primary;
    z-index: 2;
    @media only screen and (max-width: 990px) {
      display: none;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(0, -50%);
    border-top: 800px solid $brand-primary;
    border-right: 300px solid transparent;
    z-index: 2;
    @media only screen and (max-width: 990px) {
      display: none;
    }
  }
  h2 {
    z-index: 3;
    color: $white;
    @include rwd-to($tablet) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  a.button {
    z-index: 3;
    @include rwd-to($tablet) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  figure {
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
    z-index: 1;
    height: 100%;
    text-align: right;
    @include rwd-to(991) {
      position: relative;
      width: 100%;
      height: auto;
    }
    figcaption {
      position: absolute;
      right: 50px;
      bottom: 50px;
      width: 200px;
      height: 200px;
      background: $brand-primary-first;
      display: flex;
      align-items: center;
      text-align: center;
      padding: 10px 35px;
      @include sq-border-radius(50%);
      @include rwd-to(991) {
        max-width: 100%;
        margin-bottom: 20px;
        margin: auto;
        top: 0;
        bottom: 0;
        width: 150px;
        height: 150px;
        padding: 10px 25px;
        right: 20px;

      }
      &:before {
        content: '';
        background: $brand-primary url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAOCAYAAAFGCjzVAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPFJREFUeNpi+P//vwEQM8AwI4gAAjABBIwAAQQTAAMmKA0WAQggDKVA/AGI+UFsFiRBmCIYmwEggFDMRAbI5i8AYgM4G8mNMLAAbAqy42GCIAwQQMiqLwCxAppCZFwAMxGbq3GBhXB1OEz9j+5WZAwQQDiDAA8wgHnwP9TNuGwG+fUBVB0DcsjAwAekaF+A5lQG9GBFV/AfmwZccYFXAzZNOJ1ESBOyRqzyAAEGIiZAPT8BPd1TiA2QzSYUBhuAOIEIQxOgav/jSkzEhB05YAGx4U2uhQvwxREhvIASC0ixCJ+FC4jVT05GT4CWpBtI0QQAgXa8xF/fmiMAAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: center center;
        display: block;
        width: 50px;
        height: 50px;
        position: absolute;
        top: -25px;
        left: 0;
        right: 0;
        margin: 0 auto;
        @include sq-border-radius(50%);
      }
      span {
        font-weight: 200;
        vertical-align: middle;
        max-height: 140px;
        overflow: hidden;
        @include rwd-to(991) {
          font-size: pxToEm(12);
          max-height: 100px;
        }
      }
    }
    img {
      width: 100%;
      margin: 0;
      @include rwd-to(991) {
        max-width: 100%;
        margin-bottom: 20px;
      }
    }
  }
  &__content {
    color: $white;
    width: 75%;
    @include rwd-to(991) {
      width: unset;
    }
    p {
      position: relative;
      z-index: 101;
      width: 50%;
      @include rwd-to(991) {
        width: 100%;
      }
      @include rwd-to($tablet) {
        padding-left: 20px;
        padding-right: 20px;
      }
      &.intro {
        font-weight:bold;
        font-size: 24px;
        color:#fff;
      }
    }
    .button {
      font-size: 18px;
    }
  }
}