/* Module: S */
.work-map {
  position: relative;
  margin-bottom: 27px;
  .google-map {
    overflow: hidden;
    height: 0;
    padding-top: 480px;
    @media (max-width: 768px) {
      padding-top: 430px;
    }
    &:before {
      content: '';
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f1f3f5+0,ffffff+100&1+73,0+100 */
      background: -moz-linear-gradient(left, rgba(241, 243, 245, 1) 0%, rgba(251, 252, 252, 1) 73%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(241, 243, 245, 1) 0%, rgba(251, 252, 252, 1) 73%, rgba(255, 255, 255, 0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(241, 243, 245, 1) 0%, rgba(251, 252, 252, 1) 73%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f3f5', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
    }
    width: 100%;
    svg {
      position: absolute;
      top:0;
      left: 0;
      path {
        fill: $brand-primary-second;
        stroke: $brand-primary;
        stroke-width: 2;
        opacity: 0.4;
        -webkit-transition: all 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
        transition: all 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
        &.animate {
          opacity: 0.5;
          fill: $brand-primary-first;
          stroke: #7d8924;
        }
      }
    }
  }
  &__content {
    position: absolute;
    top: 20px;
    z-index: 6;
    width: 350px;
    @media (max-width: 768px) {
      //top: 5px;
      //left:38px;
      width: 100%;
      padding-left: 15px;
      padding-right:15px;
    }
    h2 {
      font-size: 28px !important;
      margin-top: 0;
    }
    p {
      font-size: 16px !important;
    }
    .list-map {
      list-style: none;
      padding: 0;
      li {
        //line-height: 1.5;
        font-size: 16px !important;
        font-weight: 200;
        margin-bottom: 1em;
        cursor: pointer;
        color:$brand-primary;
        -webkit-transition: all 0.7s ease-out;
        -moz-transition: all 0.7s ease-out;
        -ms-transition: all 0.7s ease-out;
        -o-transition: all 0.7s ease-out;
        transition: all 0.7s ease-out;
        &:hover {
          margin-left:0.25em;
          color:$font-grey-dark !important;
        }
      }
    }
  }
}