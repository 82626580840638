/* Base Modding from standard bootstrap */

// Body reset
// --------------------------------------------------

$font-family-sans-serif:  FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:          16px;



//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.25; // 20/14 = 1.428571429; 20/16 = 1.25;
//** Background color for `<body>`.
$body-bg:               $gray-lighter;
//** Global text color on `<body>`.
$text-color:            $gray-darker;

html {
  font-size: $font-size-base;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  -webkit-overflow-scrolling: touch;
}

body {
  font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  font-size: 16px;
}
.section{
  &__bg{
    &--green{
      background: #f1f3f5;
    }
    &--blue{
      background: #E5F7F8;
    }
  }
}



// Links
// --------------------------------------------------

//** Global textual link color.
$link-color: $brand-primary-second;
//** Link hover color set via `darken()` function.
$link-hover-color:      darken($link-color, 15%);
//** Link hover decoration.
$link-hover-decoration: underline;

a:not(.button) {
  color: $brand-primary;
  text-decoration: underline;

  &:hover,
  &:focus {
    cursor: pointer;
    color: $brand-primary !important;
  }

  &:focus {
    @include tab-focus;
  }
}

// container
// --------------------------------------------------
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width:         10px;

.container{
  padding-left:$grid-gutter-width/2;
  padding-right:$grid-gutter-width/2;
  @include rwd-to($tablet) {
    padding-left:15px;
    padding-right:15px;
  }
  //box-sizing:content-box;
}

// columns
// --------------------------------------------------
.col-md-3 {
    box-sizing: border-box;
}