/* Module: S */
.team-member {
  .team-tile {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .accordion {
    .accordion-title {
      h2 {
        padding: 15px;
      }
    }
    .accordion-body {
      p {
        padding: 15px;
        color: #666666;
        font-weight: 200;
        margin: 0;
      }
      .team_social {
        @extend %list-reset;
        display: inline-block;
        padding-left: 10px;
        padding-bottom: 20px;
        li {
          float: left;
          margin: 0;
          .teamicon {
            display: block;
            width: 36px;
            height: 36px;
            margin: 5px;
            &.linkdin {
              background: url(mysource_files/team_linkdin_grey.svg) 50% 50%/36px 36px no-repeat;
              &:hover {
                background: url(mysource_files/team_linkdin.svg) 50% 50%/36px 36px no-repeat;
                .gha-cube & {
                  background: url(mysource_files/gha-cube-team_linkdin.svg) 50% 50%/36px 36px no-repeat;
                }
                .gha-wlhp & {
                  background: url(mysource_files/gha-wlhp-team_linkdin.svg) 50% 50%/36px 36px no-repeat;
                }
                .dunedin & {
                  background: url(mysource_files/dunedin-team_linkdin.svg) 50% 50%/36px 36px no-repeat;
                }
                .loretto & {
                  background: url(mysource_files/loretto-team_linkdin.svg) 50% 50%/36px 36px no-repeat;
                }
              }
            }
            &.twitter {
              background: url(mysource_files/team_twitter_grey.svg) 50% 50%/36px 36px no-repeat;
              &:hover {
                background: url(mysource_files/team_twitter.svg) 50% 50%/36px 36px no-repeat;
                .gha-cube & {
                  background: url(mysource_files/gha-cube-team_twitter.svg) 50% 50%/36px 36px no-repeat;
                }
                .gha-wlhp & {
                  background: url(mysource_files/gha-wlhp-team_twitter.svg) 50% 50%/36px 36px no-repeat;
                }
                .dunedin & {
                  background: url(mysource_files/dunedin-team_twitter.svg) 50% 50%/36px 36px no-repeat;
                }
                .loretto & {
                  background: url(mysource_files/loretto-team_twitter.svg) 50% 50%/36px 36px no-repeat;
                }
              }
            }
          }
        }
      }
    }
  }
  .team-member-description {
    h1 {
      font-size: 24px;
      color: $font-grey-dark;

      //.gha-cube & {
      //  color: $cube-main-color-green;
      //}
      //.gha-wlhp & {
      //  color: $wlhp-main-color-blue;
      //}
      //.dunedin & {
      //  color: $dunedin-main-color-blue;
      //}
      .loretto & {
        color: $font-grey-dark;
      }
      margin: 0 0 10px 0;
      padding-top: 30px;
    }
    h2 {
      font-size: 22px;
      font-weight: 200;
      color: #666666;
      margin: 0 0 20px 0;
      &:before {
        content: none;
      }
    }
    p {
      font-weight: 200;
      color: #333333;
    }
  }
}