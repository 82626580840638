/* Module: S */
#page-popup {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255,255,255,0.96);
  z-index: 1001;

  .page-popup__text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 400px;
    
    .page-popup {
      &__close {
        display: inline-block;
        position: relative;
        height: 48px;
        width: 48px;
        border-radius: 100px;
        cursor: pointer;
        background-color: #298FC2;
        margin-bottom: 20px;
        &:before, &:after {
          position: absolute;
          top: 7px;
          left: 22px;
          content: ' ';
          height: 34px;
          width: 3px;
          background-color: #FFF;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &__text {
        font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;

        &--main {
          font-family: inherit;
          font-size: 24px;
          font-weight: 700;
          line-height: 31px;
          color: #298FC2;
          margin-bottom: 20px;
        }
  
        &--body {
          font-family: inherit;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          color: #222222;
          margin-bottom: 20px;
          
          &> a {
            text-decoration: underline;
            font-weight: 600;
            color: #298FC2;
          } 
        }
  
        &--note {
          font-family: inherit;
          font-size: 16px;
          font-weight: 200;
          line-height: 22px;
          color: #222222;
          
        }
      }
    }
  }
}