/* Module: S */
.home-contact {
  padding-bottom: 40px;
  &__wrapper {
    background: $white;
    padding: 35px 0 30px 0;
    margin-top: -100px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    z-index: 1;

    @include rwd-to(1400) {
      margin-top: -50px;
    }
    @include rwd-to(1150) {
      margin-top: -25px;
    }
    @include rwd-to(991) {
      padding-top: 10px;
    }
  }
  &__block {
    //background: url("mysource_files/call.svg") no-repeat;
    //padding-left: 70px;
    height: 50px;
    //margin-top: 31px;
    //width: 300px;
    p {
      color: $bright-gray;
    }
    a {
      font-size: 22px;
      display: block;
      color: $base-font-colour;
      margin-top: 10px;
    }
  }
  &__item {
    position: relative;
    border-right: 1px solid #d9d9db;
    line-height: 1.15em;
    padding: 15px 30px 0 30px;
    h3 {
      font-size: 22px !important;
    }
    span {
      display: block;
    }
    p {
      color: $bright-gray;
    }
    a {
      display: inline-block;
    }
    @include rwd-to(991) {
      border-width: 0;
      border-bottom: 1px solid #d9d9db;
      width: 100%;
    }
    @media only screen and (max-width: 990px) {
      min-height:270px;
    }
    .button {
      font-size: 18px;
      font-weight: bold;
      margin: 20px 0;
      position: absolute;
      left: 30px;
      background-color: $brand-primary-first;
      color: #fff;
      cursor: pointer;
      padding: 0.5em 2.5em;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 22px;
      position: absolute;
      bottom: -10px;
      -webkit-transition: all 0.2s ease-out;
      -moz-transition: all 0.2s ease-out;
      -ms-transition: all 0.2s ease-out;
      -o-transition: all 0.2s ease-out;
      transition: all 0.2s ease-out;
      &:hover {
        background-color: $brand-primary-first;
        color: #fff !important;
        text-decoration:underline;
      }
    }
    input {
      width: 100%;
      padding: 9px 20px 9px 20px;
      margin-top: 2px;
    }
    &:last-child {
      border-width: 0;
    }
    .marginforeqheight {
      margin-bottom: 76px;
    }
    .officesearchresultinput {
      position: relative;
      width: 100%;
      height: 50px;
      line-height: 50px;
      vertical-align: middle;
      cursor: pointer;
      padding: 0px;
      transition: opacity .25s ease-in-out;
      -moz-transition: opacity .25s ease-in-out;
      -webkit-transition: opacity .25s ease-in-out;
      border: 1px solid #cccccc;
      cursor: pointer;
      vertical-align: middle;
      margin: 0;
      color: #757575;
      height: 50px;
      line-height: 50px;
      padding-left: 22px;
      font-weight:100;
    }
  }
}

.lorettosubnav {
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  cursor: pointer;
  padding: 0px;
  transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -webkit-transition: opacity .25s ease-in-out;
  border: 1px solid #cccccc;
  //border-radius: 3px;
  cursor: pointer;
  background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
  background: url("mysource_files/dropdown.svg") right center no-repeat,
  -webkit-linear-gradient(right, rgba(102,102,102,1) 40px,rgba(255,255,255,1) 40px);
  background: url("mysource_files/dropdown.svg") right center no-repeat,
  -moz-linear-gradient(right, rgba(102,102,102,1) 40px,rgba(255,255,255,1) 40px);
  background: url("mysource_files/dropdown.svg") right center no-repeat,
  linear-gradient(to left, rgba(102,102,102,1) 40px,rgba(255,255,255,1) 40px);
  &:hover, &:focus {
    background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -webkit-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -moz-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    linear-gradient(to left, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
  }

  p {
    vertical-align: middle;
    margin: 0;
    color: #757575;
    height: 40px;
    line-height: 40px;
    padding-left: 22px;
  }
  ul {
    position: absolute;
    list-style: none;
    padding: 0;
    margin: 0;
    top: 15px;
    left: -15px;
    &.lorettodropdwn {
      display: none;
      width: 100%;
      z-index: 2;
      li {
        line-height: 15px;
        font-weight: 200;
        color: #222222;
        background-color: white;
        border-left: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        //padding: 15px;
        padding:0;
        &:first-child {
          border-top: 1px solid #cccccc;
        }
        &:last-child {
          border-bottom: 1px solid #cccccc;
        }
        a {
          color: #222222;
          height:100%;
          width:100%;
          padding:15px;
          display: inline-block;
          font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-weight: 200;
          //&:hover, &:focus {
          //  text-decoration: none;
          //}
        }
      }
    }
  }
  &.nav-is-visible {
    ul{
      &.lorettodropdwn {
        display: block;
        li {
          &:hover, &:focus {
            color: $white !important;
            background-color: $brand-primary-first;
            &> a {
              color: $white !important;
            }
          }
        }
      }
    }
  }
}

.cta-location p {
  margin-bottom:22px;
}