/* Module: S */
.landing-page__secondlevel {
  display: block;
  h1 {
    margin-top: 34px;
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: 0;
    color: $font-grey-dark;

    // These overrides won't be needed when we stop using the gha-cube and gha-wlhp body classes
    .gha-cube &,
    .gha-wlhp &,
    .dunedin &,
    .loretto & {
      color: $font-grey-dark;
    }

  }

  h2 {
    font-size: 24px;
    line-height: 31px;
    font-weight: 200;
    margin-bottom: 30px;
    margin-top: 0;
    color: $brand-primary;
    font-family: SinkinSans-Light;

    .gha-cube & {
      color: $cube-main-color-green;
    }
    .gha-wlhp & {
      color: $wlhp-main-color-blue;
    }
    .dunedin & {
      color: $dunedin-main-color-blue;
    }

    //font-size: pxToEm(24);
    max-width: 800px;
  }

  p {
    max-width: 800px;
  }

  .landing-page-content__bottom {
    display: table-cell;
    &.text {
      padding-right: 15px;
    }
  }

  .landing-page-box, .landing-page-box__wide {
    background: #ebf9fa !important;
    border-bottom: solid #c0ecee 3px !important;

    //.gha-cube & {
    //  background: #faf2f5;
    //  border-bottom: solid darken(#faf2f5, 20%) 3px;
    //}
    //.gha-wlhp & {
    //  background: #faf2f5;
    //  border-bottom: solid darken(#faf2f5, 20%) 3px;
    //}
    //.dunedin & {
    //  background: $dunedin-light-bg;
    //  border-bottom: solid $dunedin-light-border 3px;
    //}
    .loretto & {
      background: #ebf9fa !important;
      border-bottom: solid #c0ecee 3px !important;
    }
  }
}

.landing-page-boxes {
  &.landing-page__secondlevel {
    @media (min-width: 768px) {
      margin-right: -17px !important;
    }
  }
}
