/* Module: S */
.landing-page-content {
  margin-top:0.67em;
  display: table;
  h1 {
    margin-top: 33px;
    padding-top: 1px;
    font-size: 24px;
    margin-bottom: 20px;
    letter-spacing: 0;
  }

  h2 {
    color: $brand-primary;
    line-height: 31px;
    margin-top: 0;
    font-family: SinkinSans;
    font-size: 22px;
    margin-bottom:10px !important;
    max-width: 800px;
    padding-top: 0;
    &:before {
      content: none;
    }
  }

  p {
    margin-bottom: 1.5em;
    color: #222;
    font-weight: 200;
  }

  .landing-page-content__bottom {
    //background-color: red;
    //float: none;
    display: table-cell;
    vertical-align: middle;
    &.text {
      padding-right: 7%;
      vertical-align: top;
      @media (max-width: 767px) {
        padding-right: 15px;

      }
    }
  }
  .landingimgwrapper {
    margin: 0 auto;
    width: 92%;
    overflow: hidden;
    border-radius: 50%;
    margin-bottom:50px;
    .landing-page-content__bottom__img {
      max-height: 250px;
      //margin-bottom: pxToEm(50);
      float: right;
    }
    img {
      margin: 0;
    }
  }

//
}
