/* Module: S */
.stories {
  padding: 40px 0 0 0 ;
  a {
    img {
      width: 100%;
      margin: 0;
    }
  }

}