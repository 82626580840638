/* Module: S */
//.service-map-new {
//  position: relative;
//  margin-bottom: 30px;
//  .google-map {
//    height: 600px;
//    width: 100%;
//  }
//  .tempmap {
//    width: 100%;
//    height: 600px;
//    background-color: $bright-gray;
//  }
//  .search-map {
//    position: absolute;
//    top: 25px;
//    z-index: 2;
//    width: 100%;
//    .searchfilter {
//      @media (max-width: 767px) {
//        padding: 15px 0px 13px 0px;
//      }
//      background: rgba(255, 255, 255, 0.8);
//      border-radius: 3px;
//      padding: 15px 20px 13px 20px;
//      color: white;
//      position: relative;
//      height: 50px;
//      -webkit-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
//      -moz-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
//      box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
//      border: 1px solid #939497;
//      height: auto;
//      h3 {
//        display: inline-block;
//        color: black;
//        padding: 0 40px 0 0;
//        margin: 0;
//        font-weight: 300;
//        background: url(mysource_files/search_black.svg) 95% center/20px 20px no-repeat;
//        @media (max-width: 767px) {
//          display: block;
//          text-align: center;
//          padding-bottom: 20px;
//        }
//      }
//      .location, .services {
//        display: inline-block;
//        position: relative;
//        cursor: pointer;
//        &.active {
//          span {
//            background: #eaeaea url(mysource_files/search_dropdown.svg) 92% center/20px 20px no-repeat;
//          }
//        }
//        span {
//          margin-left: 20px;
//          padding: 9px 40px 7px 10px;
//          border-radius: 3px;
//          border: 1px solid #939497;
//          color: black;
//          font-weight: 200;
//          background: url(mysource_files/search_dropdown.svg) 92% center/20px 20px no-repeat;
//        }
//      }
//      .location, .services {
//        .ulwrapper {
//          display: none;
//          border-radius: 3px;
//          padding: 20px;
//          background-color: white;
//          border: 1px solid #939497;
//          position: absolute;
//          top: 30px;
//          left: 20px;
//          ul {
//            //@media (max-width: 620px) {
//            //  columns: 1;
//            //  width: 278px;
//            //}
//            list-style: none;
//            columns: 2;
//            width: 530px;
//            padding: 0;
//            margin: 0;
//            padding-bottom: 20px;
//            border-bottom: 1px solid #939497;
//            li {
//              padding: 5px;
//              margin: 0;
//              color: black;
//            }
//          }
//          .mapclear, .mapapply {
//            padding-top: 20px;
//          }
//          .mapclear {
//            float: left;
//            color: $font-grey;
//          }
//          .mapapply {
//            float: right;
//            color: $brand-primary-second;
//          }
//        }
//        &.active {
//          .ulwrapper {
//            display: block;
//          }
//        }
//      }
//      //.location {
//      //  .ulwrapper {
//      //    @media (max-width: 984px) {
//      //      left: -96px;
//      //    }
//      //    @media (max-width: 767px) {
//      //      left: 1px;
//      //    }
//      //  }
//      //}
//      //.services {
//      //  .ulwrapper {
//      //    @media (max-width: 1200px) {
//      //      left: -124px;
//      //    }
//      //    @media (max-width: 984px) {
//      //      left: -240px;
//      //    }
//      //    @media (max-width: 767px) {
//      //      left: -143px;
//      //    }
//      //  }
//      //}
//    }
//  }
//}

.service-map-new {
  position: relative;
  margin-bottom: 30px;
  .google-map {
    height: 680px;
    width: 100%;
    @media (max-width: 768px) {
      visibility: hidden;
      height: 635px;
    }
  }
  .tempmap {
    width: 100%;
    height: 600px;
    background-color: $bright-gray;
  }
  .search-map {
    position: absolute;
    top: 25px;
    left: 25px;
    @media (max-width: 768px) {
      bottom: 10px;
      right: 10px;
      top: 10px;
      left: 10px;
    }
    z-index: 2;
    width: auto;
    display: inline-block;
    .searchfilter {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 3px;
      padding: 25px 20px 13px 20px;
      color: white;
      position: relative;
      width: 300px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      -moz-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      border: 1px solid #939497;
      @media (max-width: 768px) {
        width: 100%;
      }
      h3 {
        font-size: 22px;
        display: inline-block;
        color: black;
        padding: 0 40px 0 0;
        margin: 0;
        font-weight: 300;
      }
      .location, .services {
        color: black; //$font-grey;
        position: relative;
        h3 {
          padding-top: 12px;
          font-size: 18px;
          margin: 15px 0px 8px 0;
          color: black;
          display: block;
        }
        .ulwrapper {
          ul {
            &.mapul {
              list-style: none;
              padding: 0;
              margin: 0;
              li {
                input[type=checkbox] {
                  position: relative;
                  -webkit-border-radius: 10px;
                  -moz-border-radius: 10px;
                  border-radius: 10px;
                  -khtml-border-radius: 10px;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  background-color: #fff;
                  width: 20px;
                  height: 20px;
                  border: 1px solid #999999;
                  vertical-align: -20%;
                  box-sizing: border-box;
                  display: inline-block;
                  margin-top: 0;
                  &:after {
                    position: absolute;
                    width: 10px;
                    top: 4px;
                    left: 4px;
                    height: 10px;
                    content: " ";
                    //background-color: $brand-primary-second;
                    background-color: transparent;
                    @include sq-border-radius(5px);
                  }
                  &:checked:after {
                    position: absolute;
                    width: 10px;
                    top: 4px;
                    left: 4px;
                    height: 10px;
                    content: " ";
                    background-color: $brand-primary-second;
                    @include sq-border-radius(5px);
                  }
                  ~ label {
                    margin-bottom: 10px;
                    font-weight: 200;
                    font-size: 16px;
                    color: #727272;
                  }
                }
                input::-ms-check {
                  color: $brand-primary-second;
                }
              }
            }
          }
          .mapapply {
            padding-top: 20px;
            text-align: left;
          }
        }
      }

    }
  }
}