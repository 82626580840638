/* Module: S */
.justtext {
  padding: 40px 0;
  @media (max-width: 768px) {
    padding: 25px 15px;
  }
  &.blubg {
    background-color: $brand-primary;
    .button {
      @extend .button;
      // margin-top:100px;
      margin-bottom: 60px;
      background-color: $brand-primary-first;
    }
    h2 { 
      color: white;
      font-size: 28px;
    }
    .signature {
      color: white;
      font-size: 20px;
      font-family: 'SinkinSans-Italic', "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
  }
  .twocolumns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 100px;
    -moz-column-gap: 100px;
    column-gap: 100px;
    p {
      display: inline-block;
    }
    @media (max-width: 768px) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  }
}