/* Module: S */
.slider {
  position: relative;
  padding: 20px 0 60px 0;
  h2 {
    padding-left: 0;
    @include rwd-to($tablet) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  &__wrapper {
    position: relative;
    &:before {
      content: '';
      width: 150px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f1f3f5+0,f1f3f5+100&1+61,0+100 */
      background: -webkit-linear-gradient(left, rgba(241, 243, 245, 1) 0%, rgba(241, 243, 245, 1) 61%, rgba(241, 243, 245, 0) 100%);
      background: -o-linear-gradient(left, rgba(241, 243, 245, 1) 0%, rgba(241, 243, 245, 1) 61%, rgba(241, 243, 245, 0) 100%);
      background: linear-gradient(to right, rgba(241, 243, 245, 1) 0%, rgba(241, 243, 245, 1) 61%, rgba(241, 243, 245, 0) 100%); /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f1f3f5', endColorstr='#00f1f3f5', GradientType=1); /* IE6-9 */
      @include rwd-to($tablet) {
        background: transparent;
      }
    }
    &:after {
      content: '';
      width: 150px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 3;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f1f3f5+0,f1f3f5+100&0+0,1+39 */
      background: -webkit-linear-gradient(left, rgba(241, 243, 245, 0) 0%, rgba(241, 243, 245, 1) 39%, rgba(241, 243, 245, 1) 100%);
      background: -o-linear-gradient(left, rgba(241, 243, 245, 0) 0%, rgba(241, 243, 245, 1) 39%, rgba(241, 243, 245, 1) 100%);
      background: linear-gradient(to right, rgba(241, 243, 245, 0) 0%, rgba(241, 243, 245, 1) 39%, rgba(241, 243, 245, 1) 100%); /* FF3.6-15 */
      /* Chrome10-25,Safari5.1-6 */
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00f1f3f5', endColorstr='#f1f3f5', GradientType=1); /* IE6-9 */
      @include rwd-to($tablet) {
        background: transparent;
      }
    }
  }
  &__slide {
    margin: 10px;
    background: $white;
    @include rwd-to($tablet) {
      margin: 10px 15px;
    }
    h3 {
      font-family: SinkinSans, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
      font-size: pxToEm(30);
      position: relative;
      padding-top: 20px;

      .home .main & {
        margin-top: 20px;
      }
      &:before {
        content: '';
        width: 37px;
        height: 3px;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: 0 auto;
        background-color: $brand-primary-second;
        display: block;
      }
    }
    figure {
      width: 120px;
      height: 120px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
      @include sq-border-radius(50%);
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        max-width: none;
      }
    }
    .info__desc {
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 1.5em;
      color: $bright-gray;
    }
    > a {
      text-decoration: none;
      padding: 20px 24px 50px 24px;
      display: block;
      position: relative;
      overflow: hidden;
      @include sq-transition(box-shadow .3s ease);
      -webkit-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      -moz-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      &:hover,
      &:focus {
        text-decoration: none;
        -webkit-box-shadow: 0px 0px 15px 0px rgba(191, 191, 191, 0.8);
        -moz-box-shadow: 0px 0px 15px 0px rgba(191, 191, 191, 0.8);
        box-shadow: 0px 0px 15px 0px rgba(191, 191, 191, 0.8);
        .info__overlay {
          bottom: 0;
        }
      }
    }
  }
  &__title {
    text-align: center;
    color: #262626;
    h3 {
      font-size: 20px;
    }
  }
  .info__overlay {
    overflow: hidden;
    bottom: -30px;
    border-top: 10px solid $brand-primary-first;
    border-bottom: 10px solid $brand-primary-first;
    position: absolute;
    left: 0;
    width: 100%;
    background-color: $brand-primary-first;
    text-align: center;
    @include sq-transition(bottom .3s ease);
    @include rwd-to(1025) {
      bottom: 0;
    }
    span {
      color: $white;
    }
  }
  .slick-prev, .slick-next {
    position: absolute;
    border-width: 0;
    width: 60px;
    height: 60px;
    line-height: 0;
    z-index: 4;
    -webkit-font-smoothing: antialiased;
    //background-color: $brand-primary-first;
    text-indent: -9999px;
    @include sq-transition(background .3s ease);
    @include sq-border-radius(50%);
    @include sq-transition();
    @include rwd-to($tablet) {
      width: 42px;
      height: 42px;
      top: -60px;
      margin-top: 0;
    }
    &:before {
      color: #0f2975;
      line-height: 12px;
    }
    &:hover,
    &:focus {
      background-color: $brand-primary-second;
    }
  }
  .slick-prev {
    left: 20px;
    background: $brand-primary-first url('{{file_dest}}/icon-arrow-left.png') 45% no-repeat;
    @include rwd-to($tablet) {
      right: 60px;
      left: auto;
      background-size: 10px 20px;
    }
  }
  .slick-next {
    right: 20px;
    background: $brand-primary-first url('{{file_dest}}/icon-arrow-right.png') 55% no-repeat;
    @include rwd-to($tablet) {
      right: 15px;
      background-size: 10px 20px;
    }
  }
  .slick-dots {
    position: relative;
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    z-index: 8;
    padding-left: 0;
    padding-top: 10px;
    @include rwd-to(1024) {
      margin: 10px auto 0;
    }
    li {
      display: inline-block;
      margin: 0 1px;
      button {
        font-size: 0;
        border-width: 0;
        width: 16px;
        height: 16px;
        background: #6d6e71;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        border: 2px solid #fff;
        transition: all 0.4s;
        -webkit-transition: all 0.4s;
        -moz-transition: all 0.4s;
        padding: 0;
        opacity: 0.5;
        @include rwd-from(1025) {
          &:focus,
          &:hover {
            background: $brand-primary-second;
            border-color: $brand-primary-second;
            opacity: 1
          }
        }
      }
    }
    .slick-active {
      button {
        background: $brand-primary-second;
        border-color: $brand-primary-second;
        opacity: 1;
      }
    }
  }
}