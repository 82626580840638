/* Module: S */
.team {
  .main-header__team {
    @extend .main-header;
  }
  p {
    color: #333333;
    padding-right: 33%;
    line-height: 2em;
    @media (max-width: 767px) {
      padding-right: 0;
    }
  }
  ul {
    @extend %list-reset;
    display: flex;
    flex-wrap: wrap;
    clear: both;

    &:before, &:after {
      content: '';
      display: table;
      line-height: 0;
    }

    li {
      flex-basis: 33%;

      .team-tile {
        width: 100%;
        margin: 0;
      }
      h3 {
        &:before {
          content: none;
        }
        a {
          //.gha-cube & {
          //  color: $cube-main-color-green;
          //}
          //.gha-wlhp & {
          //  color: $wlhp-main-color-blue;
          //}
          //.dunedin & {
          //  color: $dunedin-main-color-blue;
          //}
          .loretto & {
            color: $brand-primary-second;
          }
        }
        color: $brand-primary;
        font-size:  18px;
        font-weight: 500;
        margin-bottom: 0px;
        &:hover {
          color: $dark-red;
        }
      }
      p {
        color: #666666;
        font-size: 16px;
        padding: 0;
        margin-bottom: 30px;
      }
    }

    @media screen and (max-width: 991px) {
      li {
        flex-basis: 50%;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }
}
