/* Module: S */
//.row.is-flex {
//  display: -webkit-box;
//  display: -webkit-flex;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-flex-wrap: wrap;
//  -ms-flex-wrap: wrap;
//  flex-wrap: wrap;
//}
//.row.is-flex > [class*='col-'] {
//  display: -webkit-box;
//  display: -webkit-flex;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-box-orient: vertical;
//  -webkit-box-direction: normal;
//  -webkit-flex-direction: column;
//  -ms-flex-direction: column;
//  flex-direction: column;
//}

.wg-camp {
  padding-bottom: 8px;
  width: 50%;
  width: calc(50% - 10px) !important;
  @media (max-width: 991px) {
    width: 100%;
    min-height: 390px;
    width: calc(100% - 10px) !important;
  }
  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.campain {
  background: url(mysource_files/cleaning.png) center bottom / cover;
  //height: 100%;
  //min-height: 390px;
  margin: 0px 5px 8px 5px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 28%, rgba(0,0,0,0.78) 82%, rgba(0,0,0,0.78) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 28%,rgba(0,0,0,0.78) 82%,rgba(0,0,0,0.78) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 28%,rgba(0,0,0,0.78) 82%,rgba(0,0,0,0.78) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#c7000000',GradientType=0 ); /* IE6-9 */
    .text {
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      padding: 50px 20px;
      p {
        color: $white;
        font-weight: 300;
        font-size: 22px;
        margin-bottom: 10px;
      }
      a:hover{color: $white;}
    }
  }
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.campain_illustration {
  background: #fceae7;
  border: 2px solid #f3aba1;

  .gha-cube & {
    background-color: $cube-light-bg;
    border-color: $cube-light-border;
  }
  .gha-wlhp & {
    background-color: $cube-light-bg;
    border-color: $cube-light-border;
  }
  .dunedin & {
    background-color: $dunedin-light-bg;
    border: 2px solid $dunedin-light-border;
  }
  .loretto & {
    background-color: $loretto-light-bg;
    border: 2px solid $loretto-light-border;
  }

  margin: 0px 5px 8px 5px;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .text {
      padding: 0 16% 40px 16%;
      @media (max-width: 360px) {
        padding: 0 5% 20px 5%;
      }
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      h2 {
        margin: 0 0 16px;
        font-weight: 200;
        font-size: 28px;
        line-height: 3.2rem;
        @media (max-width: 530px) {
          font-size: 22px;
          line-height: 2.2rem;
        }
      }
      p {
        color: #222;
        font-weight: 300;
        font-size: 16px;
        margin-bottom: 28px;
      }
    }

    .illustration {
      width: 230px;
      margin: 24px auto;
    }
  }
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
