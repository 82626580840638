/* Module: S */
.footer {
  color: $font-grey;
  font-weight: 200;
  overflow: hidden;
  background-color: $footer-bg-2;
  
  h2, h3 {
    color: $brand-primary-first;
    font-size: 18px;
  }
  h3 {
    font-size: 16px;
  }
  h4{
    color: $bright-gray;
    font-weight: 400;
  }

  h2 {
    margin-top: 40px;
    font-weight: 400;
    border-color: #c9c9c9;
    padding-top:0;
    font-family:SinkinSans;
    font-size: 18px;
    //border-bottom:1px solid #ccc;
    margin-bottom: 0px;
    @media (max-width: 991px) {
      width: 250px;
    }
  }

  .graybottomline {
    border-bottom:1px solid #ccc;
  }
  
  a {
    color: #000;
    text-decoration: none;
    .gha-cube & {
      color: #000;
    }

    .gha-wlhp & {
      color: #000;
    }
  }
  
  .background-digonal-stripes {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  &__top {
    background: #f8f8f8;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
  
  &__bottom {
    font-size: 12px;
    font-weight: 100;
    padding-right: 15%;
    position: relative;
    padding-bottom: 4em;
    
    .icon-info {
      height: 25px;
      width: 25px;
      border: none;
    }
    a {
      color: $font-grey;
      font-weight: 100;
      text-decoration: none;
      .gha-wlhp & {
        color: $font-grey;
      }
    }
    
  }
  
  &__headers {
    font-family:"FSme","Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 23px;

  }
  
  &__subheader {
    font-weight: bold;
    margin: 0;
    padding: 0;
  }
  
  &__social-icons {
    display: inline-block;
    height: 23px;
    width: 42px;
  }
  
  &__links {
    margin: 0;
    overflow: hidden;
    li {
      float: left;
      padding-left: 20px;
      font-weight: bold;
      position: relative;
      
      &:before {
        content: ".";
        font-size: 12px;
        left: 8px;
        top: 3px;
        line-height: 0px;
        position: absolute;
      }
      
      &:first-child {
        padding: 0;
        
        &:before {
          content: "";
        }
      }
      
      a {
        font-weight: 100;
        color: $font-grey;
        text-decoration: none;
      }
    }
  }
  
  &__wg-logo {
    display: block;
    height: 40px;
    width: 133px;
    text-align: right;
    position: absolute;
    top: 15px;
    right: 0;
    background: url('{{file_dest}}/wg-grey-logo.png') center /contain no-repeat;
    background: url('{{file_dest}}/wg-grey-logo.svg') center /contain no-repeat;

  }
  &__bottom-info-text {
    p:first-child {
      max-width: 820px;
    }
  }
}

@media (max-width: 991px) {
  .footer {
    .background-digonal-stripes{
      left: -80px;
    }
    &__top {
      margin-bottom: 1em;
    }
    &__bottom {
      padding-right: 0;
    }
    
    &__links li {
      padding: 0;
      float:none;
      margin-bottom: 0.5em;
      &:before {
        content: '';
      } 
    }

    &__bottom-info-text {
      display: none;
      padding: 1em 0 2em;

      &.is-active {
        display: block;
      }
    }
    
    &__wg-logo {
      bottom: 1em;
      top: auto;
      background: url('{{file_dest}}/wg-grey-logo.png') center / auto 30px no-repeat;
      background: url('{{file_dest}}/wg-grey-logo.svg') center / auto 30px no-repeat;
    }
  }
}