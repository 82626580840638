/* Module: S */
.accordion {
  background-color: #f8f8f8;
  border-bottom: 3px solid #e4e4e4;
  margin-bottom: 1em;
  padding-bottom: 0;
  table {
    border: 0px;
    margin-bottom: 24px;
    thead {
      tr {
        th {
          background-color: #666666;
          color: $white;
          font-weight: 500;
          font-size: 16px;
          padding: 1em;
          margin: 0;
          border: 1px solid #666666;
          border-right: 1px solid #dfdfdf;
          &:last-child {
            border-right: 1px solid #666666;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          color: #666666;
          font-weight: 200;
          background-color: #f8f8f8;
          border: 1px solid #f8f8f8;
          padding: 0.5em 1em;
          border-bottom: 1px solid #dfdfdf;
          border-right: 1px solid #dfdfdf;
          &:last-child {
            border-right: 1px solid #f8f8f8;
          }
        }
        &:last-child {
          td {
            border-bottom: 3px solid #dfdfdf;
          }
        }
      }
    }
  }
  ul {
    margin-top: -10px;
    ul {
      padding-left: 11px;

      //li:first-child {
      //  background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 2px / 6px;
      //  padding-left: 17px;
      //  font-weight: 200;
      //
      //  .gha-cube & {
      //    background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
      //  }
      //  .gha-wlhp & {
      //    background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
      //  }
      //  .dunedin & {
      //    background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
      //  }
      //  .loretto & {
      //    background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
      //  }
      //}
    }

    list-style: none;
    padding-left: 1px;

    li {
      //background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 3px;
      font-size: pxToEm(16);
      line-height: pxToEm(24);
      font-weight: 200;
      //padding-left: 17px;
      &:last-child {
        margin-bottom: 1.5em;
      }

    }
  }

  h2 {
    background-color: #e4e4e4;
    padding: 21px 35px 16px 20px;
    margin: 0;
    cursor: pointer;
    position: relative;
    line-height: 1.3;
    font-size:  18px !important;
    color: $font-grey-dark;
    font-weight: bold;
    &:before {
      content: none;
    }

    .sq-open,
    .sq-close {
      min-width: 20px;
      position: absolute;
      right: 3%;
      top: 50%;
      margin-top: -10px;
    }

    .sq-close {
      background: url('mysource_files/expand.png') no-repeat 50% 50%;
    }
    .sq-open {
      background: url('mysource_files/collapse.png') no-repeat 50% 50%;
    }
  }

  > .row {
    padding: 0 3%;
  }

  h3 {
    font-size: 20px;
    margin: 1em 0;
    margin-bottom: 1.5em;
    font-weight: lighter;
    color: #222;
    &:before {
      content: none;
    }
  }

  li {
    margin-bottom: 1em;
    font-size: 16px;
    line-height: 1.5em;
    font-weight: 200;
    &:last-child {
      margin-bottom: 1.5em;
    }

    > ul {
      margin-top: 1em;
    }
  }
}

.accordion-body {
  .col-xs-12 {
    padding-left: 0;
  }
}

table {
  border: 0px;
  margin-bottom: 24px;
  thead {
    tr {
      th {
        p {
          color: $white;
          font-weight: 500;
          font-size: 16px;
          padding: 1em;
          margin: 0;
        }
        background-color: #666666;
        margin: 0;
        border: 1px solid #666666;
        border-right: 1px solid #dfdfdf;
        &:last-child {
          border-right: 1px solid #666666;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        p {
          color: #666666;
          font-weight: 200;
          padding: 0.5em 1em;
          margin: 0;
        }
        color: #666666;
        font-weight: 200;
        padding: 0.5em 1em;
        margin: 0;
        background-color: #f8f8f8;
        border: 1px solid #f8f8f8;
        border-bottom: 1px solid #dfdfdf;
        border-right: 1px solid #dfdfdf;
        &:last-child {
          border-right: 1px solid #f8f8f8;
        }
      }
      &:last-child {
        td {
          border-bottom: 3px solid #dfdfdf;
        }
      }
    }
  }
}
