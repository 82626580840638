/* Module: S */
//.service-map {
//  position: relative;
//  margin-bottom: 30px;
//  .google-map {
//    height: 600px;
//    width: 100%;
//  }
//  .search-map {
//
//    min-height: 330px;
//    width: 100%;
//    background: #151f29;
//    .searchfilter {
//      padding-top:15px;
//      color: white;
//      position: relative;
//      .row:before {
//        content: "Search for service";
//        background: url("{{file_dest}}/search_for_services_big.png") no-repeat 50% 50% ;
//        color: white;
//        font-size: 22px;
//        position: absolute;
//        top: 30px;
//        left: -250px;
//        width: auto;
//        height: 200px;
//        display: block;
//        @media (max-width: 1680px) {
//          content: " ";
//        }
//      }
//      p {
//        font-size: 16px;
//        margin: 25px 5px 10px 5px;
//      }
//      #servicesfilters, .locationsfilters, .servicesfilters {
//        list-style: none;
//        padding: 0;
//        margin-bottom:20px;
//        li {
//          display: inline-block;
//          width: auto;
//          padding: 10px 15px;
//          margin: 5px 10px 5px 0px;
//          border: 2px solid #00b2bf;
//          font-size: 16px;
//          cursor: pointer;
//          &:hover {
//            background: #00b2bf;
//          }
//          &.active {
//            background: #00b2bf;
//          }
//          &.passive {
//            border: 2px solid darkgray;
//            color: darkgray;
//            cursor: default;
//            &:hover {
//              background: none;
//            }
//          }
//        }
//      }
//      .button {
//        @extend .button;
//        float: right;
//        margin: 15px;
//        margin-bottom: 25px;
//        &:hover {
//          background: #00b2bf;
//        }
//      }
//    }
//  }
//}