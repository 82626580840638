/* Module: S */
.homevideo-widget {
  position: relative;
  background-color: #000;
  padding-bottom: 45%;
  @media (max-width: 768px) {
    padding-bottom: 58%;
  }
  .homevideo-overlay {
    cursor: pointer;
    z-index: 1;
    .homevideo-text {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      //max-width: 40%;
      @media (max-width: 1170px) {
        //max-width: 50%;
      }
      @media (max-width: 768px) {
        //max-width: 90%;
      }
      h2 {
        text-align: center;
        margin: 10px 0 0 0;
        padding: 0;
        font-size: 28px;
        color: white;
        &:before {
          display: none;
        }
        @media (max-width: 992px) {
          font-size: 22px;
        }
      }
      p {
        text-align: center;
        font-size: 2.1em;
        color: white;
        margin-bottom: 0px;
        svg {
          margin-top: 30px;
          @media (max-width: 768px) {
            margin-top: 15px;
            width: 50px;
            height: 50px;
          }
        }
        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
    }
  }

  .homevideo-thumbnail {
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}