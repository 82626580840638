/* Module: S */
.home-service {
  padding: 0 10px 40px;

  &__wrapper {
    background: $white;
    padding: 10px 90px 10px 90px;
    margin-top: -100px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    text-align: center;
    z-index: 2;

    @include rwd-to(1400) {
      margin-top: -50px;
    }
    @include rwd-to(1150) {
      margin-top: -25px;
    }
    @include rwd-to(991) {
      padding: 10px;
    }
    h2 {
      font-size: 40px !important;  
      font-weight: 400 !important;
      @include rwd-to(992) {
        font-size: 2.2em;
        line-height: 1.3;
      }
      
      &:before {
        content: none;
      }
    }
    .subtitle {
      font-size: 24px;
      line-height: 34px;
      @include rwd-to(992) {
        font-size: 22px;
      }
      color: $brand-primary;
      font-family: SinkinSans;
      position: relative;
      padding-top: 20px;
      
      &:before {
        content: '';
        width: 37px;
        height: 3px;
        position: absolute;
        top: 0;
        left: calc(50% - 18px);
        background-color: $brand-primary-second;
        display: block;

      }
    }
  }
}