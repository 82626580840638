/* Module: S */
.button {
  .gha-wlhp & {
    color: #fff;
  }
}
.pb2top {
  border-top: 10px solid $brand-primary;

  .gha-cube & {
    border-top-color: $cube-main-color-red;
  }

  .gha-wlhp & {
    border-top-color: $wlhp-main-color-purple;
  }
  .dunedin & {
    border-top-color: $dunedin-main-color-green ;
  }
  .loretto & {
    border-top-color: $loretto-main-color-darkblue;
  }

  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    border-top: 10px solid $white;
    top: -10px;
    border-right: 10px solid transparent;
  }
  &:after {
    content: " ";
    display: block;
    position: absolute;
    border-top: 10px solid $white;
    top: -10px;
    right:0px;
    border-left: 10px solid transparent;
  }
}
.promo-banner2 {
  @media (max-width: 992px) {
    background: #fceae7 !important;
    text-align: center;
    .gha-cube & {
      background: $cube-light-bg !important; //url('https://wg-build.squiz.co.uk/__data/assets/image/0016/2284/welfare-benefit-check.png') no-repeat 10% 50%;
    }
    .gha-wlhp & {
      background: $wlhp-light-bg !important; //url('https://wg-build.squiz.co.uk/__data/assets/image/0016/2284/welfare-benefit-check.png') no-repeat 10% 50%;
    }
    .dunedin & {
      background: $dunedin-light-bg !important; //url('https://wg-build.squiz.co.uk/__data/assets/image/0016/2284/welfare-benefit-check.png') no-repeat 10% 50%;
    }
    .loretto & {
      background: $loretto-light-bg !important; //url('https://wg-build.squiz.co.uk/__data/assets/image/0016/2284/welfare-benefit-check.png') no-repeat 10% 50%;
    }
  }
  padding: 10px;
  background-color: #fceae7;
  .gha-cube & {
    background-color: $cube-light-bg;
  }
  .gha-wlhp & {
    background-color: $wlhp-light-bg;
  }
  .dunedin & {
    background-color: $dunedin-light-bg;
  }
  .loretto & {
    background-color: $loretto-light-bg; 
  }
  color: #222;
  border-left: 10px solid #e9624f;
  border-right: 10px solid #e9624f;
  h2 {
    color: #000;
    font-size: 2.2em;
    margin-bottom: 0.5em;
    font-weight: 600;
  }
  .button {
    margin-bottom: 1em;
  }
  a {
    font-weight: lighter;
    &:hover,
    :focus {
      color: $white;
    }
  }
  p {
    @media (max-width: 992px) {
      display: none;
    }
  }

  .gha-cube & {
    border-left-color: #c04d71;
    border-right-color: #c04d71;
  }

  .gha-wlhp & {
    border-left: 10px solid #c350a4;
    border-right: 10px solid #c350a4;
  }
  .dunedin & {
    border-left: 10px solid $dunedin-light-border;
    border-right: 10px solid $dunedin-light-border;
  }
  .loretto & {
    border-left: 10px solid $loretto-light-border;
    border-right: 10px solid $loretto-light-border;
  }

}
.pb2bottom {
  border-bottom: 10px solid $brand-primary;

  .gha-cube & {
    border-bottom-color: $cube-main-color-red;
  }

  .gha-wlhp & {
    border-bottom-color: $wlhp-main-color-purple;
  }
  .dunedin & {
    border-bottom-color: $dunedin-main-color-green ;
  }
  .loretto & {
    border-bottom-color: $loretto-main-color-darkblue;
  }

  position: relative;
  margin-bottom: 2em;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    border-bottom: 10px solid $white;
    top: 0px;
    border-right: 10px solid transparent;
  }
  &:after {
    content: " ";
    display: block;
    position: absolute;
    border-bottom: 10px solid $white;
    top: 0px;
    right:0px;
    border-left: 10px solid transparent;
  }
}
@media (max-width: 767px) {
  .promo-banner {
    background-position: 8% 50%;
  }
}