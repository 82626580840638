/* Module: S */
.search-result-title {
  .main-header__search {
    @extend .main-header;

  }
}

.search-result-bar {
  background-color: #f8f8f8;
  border-bottom: 3px solid #e4e4e4;
  padding-bottom: 25px;
  margin: 0 0 25px 0;

  .searchresultinput {
    background: url("mysource_files/search-gha.svg") 98% center / 24px 24px no-repeat;
    .gha-cube & {
      background: url("mysource_files/search-cube.svg") 98% center / 24px 24px no-repeat;
    }
    .gha-wlhp & {
      background: url("mysource_files/search-wlhp.svg") 98% center / 24px 24px no-repeat;
    }
    .dunedin & {
      background: url("mysource_files/search-dunedin.svg") 98% center / 24px 24px no-repeat;
    }
    .loretto & {
      background: url("mysource_files/search-loretto.svg") 98% center / 24px 24px no-repeat;
    }
  }
  .searchresultinput-wrapper {
    position: relative;
    width: 60%;
    svg {
      position: absolute;
      right: 2%;
      top: 35px;
      width: 20px;
      .search{
        fill: $brand-primary;
        .gha-cube & {
          fill: $cube-main-color-red;
        }
        .gha-wlhp & {
          fill: $wlhp-main-color-purple;
        }
        .dunedin & {
          fill: $dunedin-main-color-green;
        }
        .loretto & {
          fill: $loretto-main-color-darkblue;
        }
      }
    }
    @media (max-width: 576px) {
      width: 50%;
      float: left;
    }
  }

  .searchresultinput {
    float: left;
    height: 38px;
    margin-top: 25px;
    padding: 0.5em;
    width: 60%;
    border: 1px solid #e4e4e4;
    font-size: 18px;
    font-weight: 300;
    color: $font-grey;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
  .button__search {
    @extend .button;
    margin-top: 25px;
    float: left;
    margin-left: 10px;
    height: 38px;
    font-weight: 200;
    padding-top: 9px;
    white-space: nowrap;

    // @media (max-width: 576px) {
    //   margin-left: 50%;
    //   transform: translateX(-75px);
    // }
    a {
      color: $white;
      text-decoration: none;
      &:hover, &:focus {
        color: $white !important;
      }
    }
    // &:focus {
    //   background-color: $brand-primary;
    //
    //   .gha-cube & {
    //     background-color: $cube-main-color-green;
    //   }
    //   .gha-wlhp & {
    //     background-color: $wlhp-main-color-blue;
    //   }
    // }
  }
}
