/* Module: S */

.main {
  .search-overlay {
    position: relative;
    background: none;
    display: block;
    z-index: 1;

    .search-overlay-inner {
      margin-top: 4%;
    }
  }
}

.search-overlay {
  top:0;
  left:0;
  display: none;
  z-index: 3;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.96);
  position: absolute;
  fieldset {
    border: 0px;
    margin-bottom: 20px;
    padding-left: 0px;
    padding-right: 0px;
    legend {
      display: none;
    }
  }
  .search-overlay-inner {
    margin-top: 8.8%;
    .search_suggestion {
      font-size: 16px;
      color: #333333;
      font-weight: 300;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc;
    }
    .suggestion_list {
      @extend %list-reset;
      li {
        font-size: 22px;
        color: $font-grey;
        font-weight: 700;
        padding: 10px;
      }
    }
    .searchresultinput-wapper {
      position: relative;
      width: calc(100% - 152px);
      float: left;
      @media (max-width: 576px) {
        width: 100%;
      }
      svg {
        position: absolute;
        right: 2%;
        top: 10px;
        width: 20px;
        .search{
          fill: #e22e14;
        }
      }
    }
  }
  .searchoverlayinput {
    float: left;
    height: 40px;
    padding: 0.5em;
    border: 1px solid #999999;
    width: 100%;
    color: $font-grey;
    font-size: 16px;
    font-weight: 300;
    background: url(mysource_files/search-loretto2.svg) 98% center/24px 24px no-repeat;
  }
  .buttonoverlay {
    @extend .button;
    float: left;
    margin-left: 7px;
    height: 40px;
    font-weight: 200;
    padding-top: 9px;
    @media (max-width: 576px) {
      margin-top: 25px;
      margin-left: 50%;
      transform: translateX(-75px);
    }
    a {
      color: $white;
    }
  }
}
