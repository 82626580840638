/* Module: S */
.landing-page-boxes {
  margin: 0 -5px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.landing-page-box {
  width: calc(25% - 10px);
}

.landing-page-box__wide {
  width: calc(33% - 10px);
}

.landing-page-box, .landing-page-box__wide {
  margin: 0 5px 0.5em;
  background: #f8f8f8;
  border-bottom: solid #e4e4e4 3px;
  //padding: 20px 10px 35px;
  padding:20px 20px 20px;

  h2 {
    font-family:SinkinSans;
    font-size: 20px;
    margin: 0;
    color: #262626;
    font-weight: 400;
    padding-top: 0px;
    &:before {
      display: none;
    }

    a,
    .gha-cube & a,
    .gha-wlhp & a,
    .dunedin & a,
    .loretto & a {
      text-decoration: none;
      color: #383838;
      // &:hover,
      // &:focus {
      //   //color: #9b0000;
      // }
    }

  }

  ul {
    margin: 0;
    font-weight: 200;

  }

  li, p {
    margin-top: 5px;
    margin-bottom: 0;
    font-weight: 200;
  }

  p {
    color: $font-grey;
    font-weight: 200;

    .gha-cube & {
      color: $font-grey;
    }
    .gha-wlhp & {
      color: $font-grey;
    }
    .dunedin & {
      color: $font-grey;
    }
    .loretto & {
      color: $font-grey;
    }

  }
}

@media (max-width: 767px) {
  .landing-page-box, .landing-page-box__wide {
    width: 100%;
  }
}
