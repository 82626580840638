/* Module: S */
/*Right hand column*/

.aside-header {
  //border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
  color: $font-grey-dark;
  margin-top:0.5em;
}
.latest-news {
  h3 {
    &:before {
      content: none;
    }
    font-family:SinkinSans;
    font-size: 20px;
    margin-top: 0;
    font-weight: normal;
    line-height: 31px;
    //.gha-wlhp & a{
    //  color: $wlhp-main-color-purple !important;
    //}
    //.dunedin & a{
    //  color: $dunedin-main-color-green !important;
    //}
    .loretto & a{
      color: $brand-primary !important;
    }
  }
  p {
    font-weight: 200;
    margin-bottom: 0;
  }
  .text {
    background-color: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 0 0 2px 2px;
  }

  .img {
    @media (max-width: 991px) {
      position: relative;
      padding-bottom: 230px;
      height: 0;
      overflow: hidden;
      margin-bottom: 0;
    }

    img {
      @media (max-width: 991px) {
        left: 0;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
      }
    }
  }

  img {
    border-radius: 2px 2px 0 0;
    margin: 0;
  }
  hr {
    border-top: 1px solid #bfbfbf;
    margin: 20px 0;
  }
}
