/* Module: S */
.duedate {
  &__box {
    background: #404042;
  }

  &__header {
    background: $brand-primary;
    color: $white;
    font-size:  18px;
    padding: 20px;
    margin: 0;

    .gha-cube & {
      background: $cube-main-color-green;
    }
    .gha-wlhp & {
      background: $wlhp-main-color-blue;
    }
    .dunedin & {
      background: $dunedin-main-color-blue;
    }
    .loretto & {
      background: $loretto-main-color-blue;
    }
  }

  &__date {
    color: $white;
    text-align: center;
    font-size: 34pt;
    font-weight: 500;
    padding-top: 55px;
    padding-bottom: 35px;
    margin-bottom: 0;

    span {
      font-size: 7.5rem;
      letter-spacing: -5px;
      font-weight: bold;
      line-height: 5rem;
      display: block;
    }
  }

  &__list {
    background: #f8f8f8;
    border-bottom: solid #e4e4e4 3px;
    padding: 20px 20px 35px;

    p {
      color: #404042;
      margin-bottom: 1em;
    }

    ul {
      -moz-column-count: 1;
      -webkit-column-count: 1;
      column-count: 1;
    }

    li {
      color: rgba($black, 0.5);
      font-size: 16px;
      line-height: 1.2rem;
      background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 0;
      margin-bottom: 1em;

      .gha-cube & {
        background-image: url('{{file_dest}}/gha-cube-bullet-arrow.svg');
      }
      .gha-wlhp & {
        background-image: url('{{file_dest}}/gha-wlhp-bullet-arrow.svg');
      }
      .dunedin & {
        background-image: url('{{file_dest}}/dunedin-bullet-arrow.svg');
      }
      .loretto & {
        background-image: url('{{file_dest}}/loretto-bullet-arrow.svg');
      }
    }
  }
}
