/* Module: S */
.promo-home {
  // background: #990000 url('mysource_files/banner.jpg') no-repeat top center;
  // background-size: cover;
  //height: 545px;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 30.5%;
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
  @include rwd-to($tablet) {
    height: 250px;
  }
  @include rwd-to(350) {
    height: 300px;
  }
  .container {
    padding: 0;
  }
  position: relative;
  &__content {
    margin-left: 35px;
    position: absolute;
    top: 30px;
    color: $white;
    max-width: 450px;
    z-index: 2;
    @include rwd-to(992) {
      max-width: 500px;
    }
    @include rwd-to($tablet) {
      max-width: 100%;
      width: 100%;
      top: 20px;
      text-align: center;
      margin-left: 0;
      padding: 0 20px;
    }
    p {
      //font-size: 18px;
      font-weight: 700;
      font-size: 24px;
      font-family: SinkinSans-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
      line-height: 34px;
      @include rwd-to($laptop) {
        font-size: 16px;
      }
      @include rwd-to(992) {
        font-size: 18px;
      }
      @include rwd-to(767) {
        margin: 0 0 20px 0;
      }
      @include rwd-to($tablet) {
        text-align: center;
      }
    }
    h2 {
      color: $white;
      @include rwd-to(992) {
        margin-top: 0;
        padding-top: 0;
      }
      @include rwd-to($tablet) {
        text-align: center;
      }
      &:before {
        .home .main & {
          content: '';
          height: 0;
          width: 0;
        }
      }
    }
    @media only screen and (max-width: 990px) {
      margin-left: 0;
    }
  }
  &:before {
    // content: '';
    // height: 100%;
    // display: block;
    // width: 40%;
    // background-color: #277371;
    // opacity: 0.5;
  }
  svg {
    .overlay-standard {
      display: block;
      @include rwd-to($tablet) {
        display: none;
      }

    }
    .overlay-mobile {
      display: none;
      @include rwd-to($tablet) {
        display: block;
      }
    }
  }
}
.home-page.promo-home {
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -35%;
    left: 0;
    width: 40%;
    height: 170%;
    background: $brand-primary;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(0, -50%);
    border-top: 800px solid $brand-primary;
    border-right: 300px solid transparent;
    z-index: 1;
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}

// --------

.promo-home.homevideo-widget {
  height: auto;
  padding-top: 0;
  position: relative;
  background-color: #000;
  padding-bottom: 30.5%;
  @media (max-width: 998px) {
    padding-bottom: 35%;
  }
  @media (max-width: 768px) {
    padding-bottom: 35%;
  }
  @media (max-width: 676px) {
    padding-bottom: 45%;
  }
  @media (max-width: 500px) {
    padding-bottom: 55%;
  }
  @media (max-width: 400px) {
    padding-bottom: 90%;
  }
  .homevideo-overlay {
    cursor: pointer;
    z-index: 1;
    .homevideo-text {
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 47%;
      transform: translate(-50%, -50%);
      //max-width: 40%;
      @media (max-width: 1170px) {
        //max-width: 50%;
      }
      @media (max-width: 768px) {
        //max-width: 90%;
      }
      h2 {
        text-align: center;
        margin: 10px 0 0 0;
        padding: 0;
        font-size: 28px;
        color: white;
        &:before {
          display: none;
        }
        @media (max-width: 992px) {
          font-size: 22px;
        }
      }
      p {
        text-align: center;
        font-size: 2.1em;
        color: white;
        margin-bottom: 0px;
        svg {
          margin-top: -40px;
          @media (max-width: 768px) {
            margin-top: -25px;
            width: 50px;
            height: 50px;
          }
          &:hover {
            color: #fff;
          }
        }
        @media (max-width: 992px) {
          font-size: 18px;
        }
      }
    }
  }

  .homevideo-thumbnail {
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.video-popup {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  border: 0;
  left: 0;
  height: 100%;

  &__close {
    z-index: 11;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    color: #fff;
    border: 1px solid #AEAEAE;
    border-radius: 30px;
    background: #605F61;
    font-size: 31px;
    font-weight: bold;
    display: inline-block;
    line-height: 0px;
    padding: 16px 8px;
    &:before {
      content: "×";
    }
    &:hover {
      text-decoration: none;
    }
  }

}


