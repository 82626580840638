/* Module: S */
.articles {
  padding: 45px 0;
  .home &__article {
    padding: 5px 0;
    h2 {
      font-size: 24px;
    }
    p {
      margin-bottom: 10px;
    }
  }
}

.rhs-wrapper {
  padding-left: 40px;
  & > div {
    background: #EBF8F7;
    margin: 20px 0;
    padding: 25px;
    // border-bottom: 2px solid #BFECEF;
  }
  &-title, a {
    color: $brand-primary;
    font-family: 'SinkinSans', "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  &-title {
    font-size: 22px;
    line-height: 31px;
    font-weight: 400;
  }

  h3:before {
    content: none;
  }

  .rhs-specialist-offer {
    ul {
      padding-left: 10px;
    }
    li {
      font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
      list-style: none;
      margin: 10px 0;
      padding-left: 1em;
      text-indent: -.7em;
      line-height: 1.5;
      font-weight: 200;
      a {
        font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight:200;
      }
    }
    li:before {
      content: "• ";
      color: $brand-primary;
      margin-left: -.7em;
      margin-right: .7em;
    }
  }

  //exist i guess
  .lorettosubnav {
    position: relative;
    width: 100%;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    cursor: pointer;
    padding: 0px;
    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
    border: 1px solid #cccccc;
    //border-radius: 3px;
    cursor: pointer;
    background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -webkit-linear-gradient(right, rgb(177, 177, 177) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -moz-linear-gradient(right, rgb(177, 177, 177) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    linear-gradient(to left, rgb(177, 177, 177) 40px,rgba(255,255,255,1) 40px);

    p {
      vertical-align: middle;
      margin: 0;
      color: #757575;
      height: 40px;
      line-height: 40px;
      padding-left: 22px;
    }

    &.nav-is-visible ul.lorettodropdwn {
      display: block;
    }

    & ul {
      position: absolute;
      list-style: none;
      padding: 0;
      margin: 0;
      top: 15px;
      left: -15px;
      &.lorettodropdwn {
        display: none;
        width: 100%;
        z-index: 2;
        li {
          line-height: 15px;
          font-weight: 200;
          color: #222222;
          background-color: white;
          border-left: 1px solid #cccccc;
          border-right: 1px solid #cccccc;
          //padding: 15px;
        }
        li:first-child {
          border-top: 1px solid #cccccc;
        }
      }
    }

    &:hover, &:focus {
      background: #ffffff url("mysource_files/lorettocare-dropdown.png") right center / 40px 40px no-repeat;
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      -webkit-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      -moz-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      linear-gradient(to left, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    }
  }
}

@media screen and (max-width: 992px) {
  .rhs-wrapper {
    padding-left: 15px;
  }
}