/* Module: S */
.socialbar {
  background-color: #3c3c3c;
  margin-bottom: 20px;
  position: relative;
  h1 {
    color: $white;
    font-weight: 200;
    font-size: 24px;
    //letter-spacing: 1px;
    margin: 15px 0;
    @media screen and (max-width: 992px) {
      text-align: center;
    }
    &:before {
      display: none;
    }
    &:after {
      content: '';
      position: absolute;
      width: 120px;
      height: 96px;
      top: -16px;
      margin-left: 40px;
      background: url(mysource_files/join-the-conversation.png) center / cover no-repeat;
      @media screen and (max-width: 992px) {
        display: none;
      }
    }
  }
  label {
    display: none;
  }
  select {
    width: 100%;
    @include sq-border-radius(0px);
    height: 40px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-size: 1.25em;
    color: #999999;
    padding-left: 20px;
    border: 0px solid #999999;
    margin: 12px 0;
    padding-right: 60px;
    cursor: pointer;
    background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -webkit-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    -moz-linear-gradient(right, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    background: url("mysource_files/dropdown.svg") right center no-repeat,
    linear-gradient(to left, rgba(0,164,153,1) 40px,rgba(255,255,255,1) 40px);
    &:hover, &:focus {
      background: #ffffff url("mysource_files/dropdown.png") right center / 40px 40px no-repeat;
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      -webkit-linear-gradient(right, rgba(124,224,211,1) 40px,rgba(255,255,255,1) 40px);
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      -moz-linear-gradient(right, rgba(124,224,211,1) 40px,rgba(255,255,255,1) 40px);
      background: url("mysource_files/dropdown.svg") right center no-repeat,
      linear-gradient(to left, rgba(124,224,211,1) 40px,rgba(255,255,255,1) 40px);
    }
    &:focus::-ms-value {
      background-color: #ffffff;
      color: black;
    }
  }
  .form__dropdown{
    position: relative;
    &-box {
      position: absolute;
      right: 0;
      top: 12px;
      width: 40px;
      height: 40px;
      background: #666666 url("mysource_files/dropdown.svg");
    }
    select:hover + .form__dropdown-box,
    select.is-selected + .form__dropdown-box {
      background-color: #e22e14;
      transition: background-color 0.3s;
      .gha-cube & {
        background-color: $cube-main-color-red;
      }
      .gha-wlhp & {
        background-color: $wlhp-main-color-purple;
      }
      .dunedin & {
        background-color: $dunedin-main-color-green;
      }
      .loretto & {
        background-color: $loretto-main-color-darkblue;
      }
    }
  }
}

.involved {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  color: #3c3c3c;
  p {
    margin-bottom: 0;
    float: left;
    padding-top: 13px;
  }
  .social {
    float: left;
    @extend %list-reset;
    display: inline-block;
    padding-left: 10px;
    padding-bottom: 0;
    li {
      float: left;
      margin: 0;
      .teamicon {
        display: block;
        width: 36px;
        height: 36px;
        margin: 5px;
        &.facebook {
          background: url(mysource_files/team_facebook_grey.svg) 50% 50%/36px 36px no-repeat;
          &:hover, &:focus {
            background: url(mysource_files/team_facebook.svg) 50% 50%/36px 36px no-repeat;
          }
        }
        &.twitter {
          background: url(mysource_files/team_twitter_grey.svg) 50% 50%/36px 36px no-repeat;
          &:hover, &:focus {
            background: url(mysource_files/team_twitter.svg) 50% 50%/36px 36px no-repeat;
          }
        }
      }
    }
  }
}

.social-hub {
  .grid {
    overflow: hidden;
  }
  .grid-sizer,
  .grid-item {
    //-webkit-transition: -webkit-transform 1s !important;
    //-moz-transition:    -moz-transform 1s !important;
    //-o-transition:      -o-transform 1s !important;
    //transition:         transform 1s !important;
    width: 33.33333%;
    padding: 10px;
    @media screen and (max-width: 992px) {
      width: 50%;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    &.hidden {
      .hidden{
        visibility:hidden;
        width:0px!important;
        height:0px!important;
        margin:0px!important;
        padding:0px!important;
      }
    }
    &.facebook {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        bottom: 23px;
        left: 30px;
        background: url(mysource_files/social_face.png) left bottom / 15px 25px no-repeat;
      }
    }
    &.twitter {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 31px;
        height: 30px;
        bottom: 23px;
        left: 30px;
        background: url(mysource_files/social_tweet.png) left bottom / 31px 25px no-repeat;
      }
    }
    .content {
      height: auto;
      .picturewrapper {
        position: relative;
        width: 100%;
        padding-bottom: 100%;
        height: 0;
        overflow: hidden;
      }
      .txt-wrapper {
        border: 1px solid #cccccc;
        padding: 20px 20px 60px 20px;
        font-size: 16px;
        color: #3c3c3c;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          top: 21px;
          left: 20px;
          background: url(mysource_files/social_logo.png) center center / 32px 32px no-repeat;
          border: 1px solid #cccccc;
        }
        .date {
          display: none;
        }
        p {
          line-height: 1.7;
          margin: 0;
          &.socialtitle {
            font-size: 16px;
            line-height: 1.2;
            padding-left: 50px;
          }
          &:nth-child(2) {
            margin-bottom: 20px;
          }
        }
        a {
          &.socialtitle {
            color: #3c3c3c;
            font-size: 16px;
            line-height: 1.2;
            padding-left: 50px;
          }
        }
        .nolink {
          color: #3c3c3c;
          &:hover {
            text-decoration: none;
          }
          &:focus {
            text-decoration: none;
          }
        }
        .break {
          word-wrap: break-word;
        }
        //.gha-cube & {
        //  &:after {
        //    background: url(mysource_files/social_logo_cube.png) center center / 32px 32px no-repeat;
        //  }
        //}
        //.gha-wlhp & {
        //  &:after {
        //    background: url(mysource_files/social_logo_wlhp.png) center center / 32px 32px no-repeat;
        //  }
        //}
        //.dunedin & {
        //  &:after {
        //    background: url(mysource_files/social_logo_dunedin.png) center center / 32px 32px no-repeat;
        //  }
        //}
        .loretto & {
          &:after {
            background: url(mysource_files/social_logo.png) center center / 32px 32px no-repeat;
          }
        }
      }
    }
  }
  #load-more {
    width: 100%;
    padding: 60px 10px;
    .load-more {
      border-top: 1px solid #cccccc;
      position: relative;
      .load-more-btn {
        border-left: 20px solid $white;
        border-right: 20px solid $white;
        position: absolute;
        display: block;
        width: 80px;
        height: 40px;
        left: 50%;
        transform: translateX(-40px);
        top: -20px;
        background: transparent url(mysource_files/show_more.png) left bottom / 40px 40px no-repeat;
        cursor: pointer;
      }
    }
  }
}
