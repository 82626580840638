/* Module: S */


.accordion-locations {
  background-image: url('{{file_dest}}/multiplemaps-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  
  .bgwhite {
    background: white;
    margin: 30px 0;
    padding: 30px 40px;
  }

  .bgwhite.in {
      margin: -50px 0 30px;
  }
}

.accordionWrap{
  .accordion {
    .loretto &-title h2 {
      font-family: SinkinSans-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size:  18px;
    }

    &-body {
      margin: 20px 0;
    }

    //ul {
    //  margin-top: 20px;
    //}
    //
    //li {
    //  padding-left: 1em;
    //  text-indent: -.7em;
    //}
    
    //li:before {
    //  content: "• ";
    //  color: #00B2BF;
    //}
  }
}

ul{
 &.find-localoffice {
   margin-top:0 !important;
   li {
     padding-left:0.5em;
     &:before {
       padding-right:10px;
     }
   }
 }
}