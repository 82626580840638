// typography
// Bold
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Bold.eot');
  src: url('{{file_dest}}/FSMeWeb-Bold.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

// BoldItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-BoldItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-BoldItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

// Heavy
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Heavy.eot');
  src: url('{{file_dest}}/FSMeWeb-Heavy.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

// HeavyItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-HeavyItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-HeavyItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-HeavyItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}

// Italic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Italic.eot');
  src: url('{{file_dest}}/FSMeWeb-Italic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

// Light
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Light.eot');
  src: url('{{file_dest}}/FSMeWeb-Light.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Light.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

// LightItalic
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-LightItalic.eot');
  src: url('{{file_dest}}/FSMeWeb-LightItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-LightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

// Regular
@font-face {
  font-family: 'FSMe';
  src: url('{{file_dest}}/FSMeWeb-Regular.eot');
  src: url('{{file_dest}}/FSMeWeb-Regular.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/FSMeWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'SinkinSans-Light';
  // font-family: 'SinkinSans-Light';
  src: url('{{file_dest}}/SinkinSans-300Light-webfont.woff') format('woff');
  font-weight: 300 !important;
}

@font-face {
  font-family: 'SinkinSans-MediumItalic';
  // font-family: 'SinkinSans-MediumItalic';
  src: url('{{file_dest}}/SinkinSans-700Bold-webfont.woff') format('woff');
  font-style: italic !important;
  font-weight: 700 !important;
}

@font-face {
  font-family: 'SinkinSans-Medium';
  // font-family: 'SinkinSans-Medium';
  src: url('{{file_dest}}/SinkinSans-700Bold-webfont.woff') format('woff');
  font-weight: 700 !important;
}

@font-face {
  font-family: 'SinkinSans-Italic';
  // font-family: 'SinkinSans-Italic';
  src: url('{{file_dest}}/SinkinSans-400Regular-webfont.woff') format('woff');
  font-style: italic !important;;
  font-weight: 400 !important;;
}

@font-face {
  font-family: 'SinkinSans';
  // font-family: 'SinkinSans';
  src: url('{{file_dest}}/SinkinSans-400Regular-webfont.woff') format('woff');
  font-weight: 400 !important;;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-regular.eot');
  src: url('{{file_dest}}/lineto-brauerneue-regular.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-italic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-italic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-bolditalic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-bolditalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-bolditalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-bold.eot');
  src: url('{{file_dest}}/lineto-brauerneue-bold.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-black.eot');
  src: url('{{file_dest}}/lineto-brauerneue-black.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brauer';
  src: url('{{file_dest}}/lineto-brauerneue-blackItalic.eot');
  src: url('{{file_dest}}/lineto-brauerneue-blackItalic.eot#iefix') format('embedded-opentype'),
  url('{{file_dest}}/lineto-brauerneue-blackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

//headers
h1, h2, h3, h4 {
  .main & {
    position: relative;
    font-family: SinkinSans-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.accordion-locations {
  p + h3 {
    margin-top: 50px !important;
  }
}

h1 {
  color: #262626;
  font-size: 24px;
  font-weight: 700;
  &:before {
    .main & {
      content: '';
      width: 37px;
      height: 3px;
      position: absolute;
      top: -15px;
      left: 0;
      background-color: $brand-primary-second;
      display: block;
      @media (max-width: 991px) {
        //top: -18px;
      }
    }
  }

}

h2 {
  font-size:  18px;
  .home .main & {
    font-size: 28px;
    font-weight: 700;
    @include rwd-to($laptop) {
      font-size: 28px;
    }
    @include rwd-to(992) {
      font-size: 22px;
    }
  }
  color: #262626;
  position: relative;
  padding-bottom: 10px;
  margin-top: 20px;
  &:before {
    .main & {
      content: '';
      width: 37px;
      height: 3px;
      position: absolute;
      top: -15px;
      left: 0;
      background-color: $brand-primary-second;
      display: block;
    }
  }
}

.home .main.loretto h2 {    
  @include rwd-to($laptop) {
    font-size: 24px;
  }
  @include rwd-to(992) {
    font-size: 28px;
  }
}

.home .main.loretto .promo-home__content h2 {
  font-size: 22px;
  font-family: SinkinSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.2;
}


h3 {
  position: relative;
  font-family: SinkinSans-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
  .home .main & {
    line-height: 34px;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: 400;
  }
  &:before {
    .main & {
      content: '';
      width: 37px;
      height: 3px;
      position: absolute;
      top: -15px;
      left: 0;
      background-color: $brand-primary-second;
      display: block;
    }
  }
}

.home .main.loretto h3  {
  font-size: 24px;
  font-family: SinkinSans-Medium, "Helvetica Neue", Helvetica, Arial, sans-serif;
}


h3, h4 {
  font-size: 16px;
}

p {
  line-height: 1.5;
  margin-bottom: 1.5em;
  font-weight: 200;
}

.header-404 {
  font-size: 2.5em;
  font-weight: 700;
  line-height: 1.3;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 1.4;
  }
}

.nogreen {
  &:before {
    .main & {
      content: none;
    }
  }
}

.yesgreen {
  &:before {
    .main & {
      content: '';
      width: 37px;
      height: 3px;
      position: absolute;
      top: -15px;
      left: 0;
      background-color: $brand-primary-second;
      display: block;
    }
  }
}