/* Module: S */

.img-wrapper {
  position: relative;
  padding-bottom: 40%;
  //padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 1em;

  .to-wrap {
    left: 0;
    max-width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
}

.intro{
  color:$brand-primary;
}
