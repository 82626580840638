/* Module: S */
.halfhalf {
  //overflow: hidden;
  .container-fluid {
    max-width: 1170px;
    @media (max-width: 992px) {
      max-width: 970px;
    }
    .halfhalf__content {
      display: -webkit-box;
      display: -webkit-flex;
      //display: -ms-flexbox;
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      &:before {
        content: normal;
      }
      &:after {
        content: normal;
      }
      .left {
        position: relative;
        background-color: $brand-primary-first;
        padding: 70px 50px;
        @media (max-width: 768px) {
          padding: 25px;
        }
        h2 {
          color: white;
          &:before {
            background-color: $brand-primary-second;
          }
        }
        p {
          color: white;
        }
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 100%;
          background-color: $brand-primary-first;
          content: " ";
          width: 50vw;
        }
        .button {
          @extend .button;
          background-color: $brand-primary-second;
          color: $black;
          &:hover {
            text-decoration: underline;
            background-color: $brand-primary;
          }
        }
      }
      .right {
        background-color: $brand-primary-second;
        padding: 70px 50px;
        @media (max-width: 768px) {
          padding: 25px;
        }
        h2 {
          &:before {
            background-color: $brand-primary-first;
          }
        }
        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 100%;
          background-color: $brand-primary-second;
          content: " ";
          width: 50vw;
        }
        .button {
          @extend .button;
          background-color: $brand-primary-first;
          &:hover {
            text-decoration: underline;
            background-color: $brand-primary;
          }
        }
      }
    }
  }
}