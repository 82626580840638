/* Module: S */
.cookie {
  background: none repeat scroll 0 0 $brand-primary-second;
  padding: 6px 20px 4px;
  text-align: center;
  color: #ffffff;
  font-size: 13px;
  .loretto & {
    background: none repeat scroll 0 0 $brand-primary;
    input, label {
      cursor: pointer;
    }
  }

  span {
    line-height: 30px;
    padding-right: 40px;
    position: relative;
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: #fff;
    &:hover {
      text-decoration: underline;
      color: $white !important;
    }
  }

  .cookie_button {
    background: url(mysource_files/closeicon_white.png) 50% 50%/12px 12px no-repeat;
    background: url(mysource_files/closeicon_white.svg) 50% 50%/12px 12px no-repeat;
    display: block;
    width: 16px;
    height: 16px;
    border: none;
    position: absolute;
    top: 6px;
    right: 0px;
  }
}

.head-fix .cookie {
  display: none;
}