/* Module: S */
.search-content {
  .result_message {
    font-size: 16px;
    color: $font-grey;
    font-weight: 700;
    margin-top:16px;
    margin-bottom: 13px;

  }
  ul.main-results {
    @extend %list-reset;
    li {
      font-weight: 300;
      border-top: 1px solid #ccc;
      color: #222222;
      padding-bottom: 10px;
      h3 {
        color: $brand-primary;

        .gha-cube & {
          color: $cube-main-color-green;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-blue;
        }
        .dunedin & {
          color: $dunedin-main-color-blue;
        }
        .loretto & {
          color: $loretto-main-color-blue;
        }

        font-size: 20px;
        margin-left: 25px;
        font-weight: 700;
        &:before {
          content: "";
          position: absolute;
          width: 20px;
          height: 20px;
          //left: 15px;
          left: 0;
          margin-left:-25px;
          background: url('{{file_dest}}/external-link.svg') 50% 50% / 15px 15px no-repeat;

          .gha-cube & {
            background-image: url('{{file_dest}}/gha-cube-external-link.svg');
          }
          .gha-wlhp & {
            background-image: url('{{file_dest}}/gha-wlhp-external-link.svg');
          }
          .dunedin & {
            background-image: url('{{file_dest}}/dunedin-external-link.svg');
          }
          .loretto & {
            background-image: url('{{file_dest}}/loretto-external-link.svg');
          }
        }
      }
      p {
        padding-right: 25%;
        @media (max-width: 767px) {
          padding-right: 0;
        }
      }
        .link-arrow__result {
          @extend .link-arrow;
          margin-left: 10px;

        }
      }
    }
  .pagination {
    > li {
      > a {
        color: $font-grey-dark;
      }
      > span {
        color: $font-grey-dark;

        .gha-cube & {
          color: $cube-main-color-green;
        }
        .gha-wlhp & {
          color: $wlhp-main-color-blue;
        }
        .dunedin & {
          color: $dunedin-main-color-blue;
        }
        .loretto & {
          color: $loretto-main-color-blue;
        }
      }
      &.active {
        > span {
          background-color: $brand-primary-first;
          border-color: $brand-primary-first;
          color: $white;

          .gha-cube & {
            background-color: $cube-main-color-green;
            border-color: $cube-main-color-green;
          }
          .gha-wlhp & {
            background-color: $wlhp-main-color-blue;
            border-color: $wlhp-main-color-blue;
          }
          .dunedin & {
            background-color: $dunedin-main-color-green;
            border-color: $dunedin-main-color-green;
          }
          .loretto & {
            background-color: $brand-primary-first;
            border-color: $brand-primary-first;
          }
        }
      }
    }
  }
  }