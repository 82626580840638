/* Tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin: .5em 0;
}

caption {
  @include sq-font-size(.8);
  font-style: italic;
}

th {
  background: $base-font-colour;
  color: invert($base-font-colour);
}

td {
  border: 1px solid $base-font-colour;
}

ul {
  padding-left: 20px;
}

/* Buttons */
.button {
  @include sq-transition(background-color .3s ease);
  background-color: $base-font-colour;
  color: invert($base-font-colour);
  display: inline-block;
  border: 0;
  padding: .5em 0.7em;
  &,
  &:visited {
    text-decoration: none;
  }
  &:before,
  &:after {
    // @include sq-transition(background-color .3s ease);
  }
  &:focus,
  &:hover {
    background-color: darken($base-font-colour, 20%);
  }
}

.button--large {
  @include sq-font-size(1.5);
}

.button--small {
  @include sq-font-size(.8);
}

/* Quotes */
blockquote {
  position: relative;
  padding-left: 3em;
  margin: 2em 0;
  &:before {
    @include sq-font-size(5);
    color: $base-font-colour;
    content: "\201C";
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1em;
  }
}

.pull-quote {
  border-top: 1px solid $base-font-colour;
  padding: 1em .5em 0 .5em;
  margin: 1em;
}

/* Highlight */
.highlight {
  background: #cccccc;
  @extend %inline-block;
  padding: .1em .5em;
}

.header-404 {
  font-size: 22px;
  font-weight: 700;
}

/* page intro text */
.page-header-intro-text {
  line-height: 1.3;
  margin-bottom: 0.75em;
  max-width: 800px;
  font-weight: 300;
  font-size: 22px;
  padding-right: 60px;
  color: $brand-primary-second;
  font-family: SinkinSans;
}

.well h3 {
  margin-top:0;
  padding-top:15px;
}