/* Module: S */
#service-result {
  .slider__slide {
    display: none;
    position: relative;
    margin: 10px 0px;
    &.slideflex {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .findmoreleft {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      border-top: 14px solid $brand-primary;
      border-bottom: 14px solid $brand-primary;
      left: 0;
      width: 100%;
      background-color: $brand-primary;
      text-align: center;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      a {
        color: white;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .findmoreright {
      position: absolute;
      bottom: 0;
      overflow: hidden;
      border-top: 14px solid $brand-primary-first;
      border-bottom: 14px solid $brand-primary-first;
      right: 0;
      width: 50%;
      background-color: $brand-primary-first;
      text-align: center;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      transition: all 0.3s ease;
      cursor: pointer;
      a {
        color: white;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .slidermaptile {
      padding: 0px 0px 70px 0px;
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
      -webkit-transition: box-shadow 0.3s ease;
      -moz-transition: box-shadow 0.3s ease;
      transition: box-shadow 0.3s ease;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      -moz-box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
      .imgcontentfigure {
        overflow: hidden;
        width: 100%;
        height: 0;
        padding-bottom: 56.5%;
        img {
          margin: 0px;
          transform: scale(1.2);
        }
      }
      .slider__title {
        text-align: center;
        padding: 20px 20px 0px 20px;
        h3 {
          font-size: 22px;
          margin-top:0px;
          padding-top: 25px;
          &:before {
            content: '';
            width: 38px;
            height: 3px;
            position: absolute;
            top: 0;
            left: calc(50% - 18px);
            right: auto;
            margin: auto;
            background-color: $brand-primary-second;
            display: block;
          }
          span {
            font-size: 16px;
          }
        }
        p {
          color: #88888a;
          margin-bottom:1.2em;
          span {
            font-weight: 500;
          }
        }
      }
    }
  }
}