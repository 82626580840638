/* Module: S */
// Pills
// -------------------------

//== Pills
$nav-pills-border-radius: 0;
$nav-pills-active-link-hover-bg: $brand-primary-second;
$nav-pills-active-link-hover-color: $white;

.header {
  padding: 25px 0 20px 0;
  border-bottom: 4px solid #dddddd;
  position: relative;

  img {
    margin-bottom: 0;
  }
  .sq-header-logo {
    margin-bottom: 15px;
    svg {
      &.lorettologo {
        width: 134px;
        @media (max-width: 991px) {
          width: 120px;
        }
      }
    }

    .header__button {
      max-width: 45px;
      display: inline-block;
      padding: 5px 1em 0.3em 0;
      height: 35px;
      float: left;
      &:last-child {
        padding-right: 0;
      }
    }

  }

  &__button {
    a:hover {
      text-decoration: none;
    }
    a:focus {
      text-decoration: none;
    }
    svg {
      height: 22px;
      width: auto;
      fill: #231f20;
      vertical-align: bottom;
      cursor: pointer;
      &:hover {
        fill: $brand-primary-first;
      }
    }
    img {
      height: 22px;
      width: auto;
    }
  }
  &__logo {
    height: 72px;
    .logoback {
      fill: $brand-primary;
    }
    .logofront {
      fill: $white;
    }
  }

}

.main-navigation {
  &__sub-nav-wrapper {
    display: none;
    position: absolute;
    top: 100%;
    background-color: $brand-primary;
    width: 100%;
    left: 0;
    z-index: 5;
    padding: 35px 0 35px;

    a:hover {
      color: #ffffff !important;
    }
    a:focus {
      color: #ffffff !important;
    }
  }

  &__sub-nav-column-wrapper {
    display: table;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
  }

  &__sub-nav-column {
    width: 20%;
    padding-left: 15px;
    padding-right: 15px;
    display: table-cell;
    &.sixcolumn {
      width: 16.66666%;
    }
    &.sevencolumn {
      width: 14.28571%;
    }
  }
  .gha-wlhp &__sub-header {
    color: white;
  }
  .dunedin &__sub-header {
    color: white;
  }

  .help {
    margin-right: 0;
    background-color: $brand-primary;
    position: relative;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 22px;

    &.main-navigation__first-level > a {
      color: $white;
      font-weight: 200;

    }

    > a {
      color: $white;
      background: transparent;

      &:hover
        //,:focus
      {
        color: $white !important;
        //background: transparent;
      }

      // &:before,
      // &:after {
      //   box-shadow: -3px 0 0 0 $brand-primary;
      //   content: '';
      //   position: absolute;
      //   right: -10px;
      // }

      // &:before {
      //   background: $brand-primary;
      //   bottom: 10px;
      //   top: 0;
      //   width: 10px;
      //   border-top-right-radius: 3px;
      // }

      // &:after {
      //   bottom: -2px;
      //   box-shadow: -4px 0 0 0 $brand-primary, 0 -4px 0 0 $brand-primary, -4px -4px 0 0 $brand-primary;
      //   border-color: $brand-primary transparent transparent $brand-primary;
      //   border-style: solid;
      //   border-width: 5px;
      // }

      //&:hover:before {
      //  box-shadow: -1px 0 0 0 $dark-red;
      //  bottom: -20px;
      //  background: $dark-red;
      //}
      //
      //&:hover:after {
      //  box-shadow: -1px -1px 0 1px $dark-red;
      //  border-color: $dark-red transparent transparent $dark-red;
      //}

      // &:focus:after {
      //   bottom: 0;
      // }
    }
  }

  .nav {
    margin-left: -23px;
    @include rwd-to($laptop) {
      margin-left: -15px;
    }
    > li:last-child {
      background: $brand-primary-first;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 22px;
      -webkit-transition-delay: 0.1s;
      transition-delay: 0.1s;
      &:hover {
        background: $brand-primary-first;
        text-decoration: underline;
      }
      &:focus {
        background: $brand-primary-first;
        text-decoration: underline;
      }
      a {
        font-weight: 700;
        color: white;
        padding: 8px 28px 8px 17px;

      }

      > a:hover,
      > a:focus {
      }
    }
  }

  li.main-navigation__first-level {
    position: static;
    height: 37px;
    float: left;

    // Links rendered as pills
    > a {
      border-radius: $nav-pills-border-radius;
      color: $gray;
      font-weight: 200;
      padding: 0 23px;
      text-decoration: none;
      @include rwd-to($laptop) {
        padding: 0 15px;
      }

      span {
        padding: 10px 0 5px 0;
        display: block;
      }

      &:hover
        //,&:focus
      {
        background-color: transparent;
        //color: $white;

      }

    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      //new version with pills
      //color: $white;
      //background-color: $nav-pills-active-link-hover-bg;
      //@include sq-border-radius(3px);

      //old version underlined
      color: $brand-primary;
      span {
        border-bottom: 2px solid $brand-primary-first;
      }

      &:hover
        //,&:focus
      {
        //old version underline
        //color: $white;
        //border-bottom: 2px solid $nav-pills-active-link-hover-bg;

      }
    }

    // &:hover, &.active:hover, {
    &.active.main-nav-hover,
    &.main-nav-hover {
      > a {
        background: $brand-primary !important;
        border-bottom: 24px solid $brand-primary;
        color: #fff !important;
        border-radius: 3px 3px 0 0;
      }

      .main-navigation__sub-nav-wrapper {
        display: block;
        a {
          text-decoration: none;
        }
      }
      &.nosubnav {
        > a {
          background: $brand-primary !important;
          border-bottom: 9px solid $brand-primary;
          color: #fff !important;
          //text-decoration: underline;
          text-decoration: none;
          border-radius: 3px;
        }
        .main-navigation__sub-nav-wrapper {
          display: none;
        }
      }
    }
  }

  &__sub-nav-wrapper {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    li {
      float: none;
      margin-top: 20px;
    }

    a {
      color: #fff;
      //background: none;
      font-weight: 200;
    }

    .main-navigation__second-level-link {
      font-weight: bold;
      font-size:  18px;
    }
  }

  &__second-level-link {
    font-weight: bold;
  }
}

.help {

  &-panel {
    //padding: 30px 0;
    padding: 0;
    display: block;

    img {
      max-width: 276px;
      width: 100%;
      padding: 0;
      padding-top: 22px;
    }

    li {
      margin-top: 12px;
    }

    h2,
    p {
      color: $white;
      font-weight: 100;
      line-height: 1.3;
    }

    h2 {
      font-size: 2rem;
      padding-bottom: 5px;
      margin-top: 0;
      max-width: 350px;
    }

    p {
      font-size: 16px;
      margin-bottom: 20px;
      max-width: 290px;
    }

    .button {
      &:focus {
        // background-color: $nav-pills-active-link-hover-bg;
        color: $white;

        // .gha-cube & {
        //   background-color: $cube-main-color-green;
        // }
        //
        // .gha-wlhp & {
        //   background-color: $wlhp-main-color-blue;
        // }
      }
    }

    &__register {
      padding-left: 40px;
    }

    &__lognow {
      padding-left: 40px;

      .list-arrow {
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .help {
    &-panel {
      img {
        max-width: 250px;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .help {
    &-panel {
      img {
        display: none;
      }
    }

    .main-navigation__sub-nav-wrapper {
      padding-top: 5px;

      br {
        display: none;
      }

      .login-panel {
        .button {
          width: 145px;
          margin-top: 0;
        }

        &__lognow {
          padding-right: 10px;
          padding-left: 0;
          margin: 0 15px;
          width: calc(100% - 30px);
        }
      }
    }
  }
}

@media (min-width: 992px) {
  .header {
    .sq-header-logo {
      .header__mobile-nav-btn {
        display: none;
      }
    }
  }

  .login {
    &-panel {
      &__lognow {
        border-right: 1px solid rgba($white, 0.4);
      }
    }
  }
}

@media (max-width: 991px) {
  .header {
    padding: 20px 0 28px;

    .main-navigation {
      margin: 0 -15px;
    }

    .main-navigation__first-level {
      display: none;
    }

    .help {
      > a,
      &:after,
      &:before {
        display: none;
      }
    }

    .main-navigation li.main-navigation__first-level {
      height: auto;
    }

    .main-navigation__sub-nav-wrapper {
      display: block;
    }

    .sq-header-logo {
      margin-bottom: 0;
    }

    &__logo {
      //height: 42px;
    }
  }

  .main-navigation li.main-navigation__first-level + li {
    margin: 20px 0 0;
  }

  li.help {
    display: none;
    width: 100%;

    > div {
      position: relative;
    }

    .login-panel {
      display: block;
      padding: 0;

      li {
        margin-top: 20px;
      }

      .button {
        width: 100%;
        text-align: center;
      }

      &__register {
        padding: 30px 10px 0 0;
      }

      &__lognow {
        border-bottom: 1px solid rgba($white, 0.7);
        padding: 10px 25px 0 15px;
        padding-bottom: 30px;

        a {
          margin-top: 20px;
        }
      }
    }
  }
}

.nav > li > a:hover, .nav > li > a:focus {
  background-color: transparent;
}
.nav > li:last-of-type > a:hover, .nav > li:last-of-type > a:focus {
  color: #fff !important;
  background-color: transparent;
}
