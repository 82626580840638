/* Module: S */
.multiplemaps {
  background-image: url('{{file_dest}}/multiplemaps-bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  .bgwhite {
    background-color: white;
    margin: 100px 0;
    box-shadow: 0px 0px 5px 0px rgba(191, 191, 191, 0.8);
    border-radius: 5px;
    padding: 50px 0;
    h2 {
      margin: 0 50px 5px 50px;
    }
    ul.location {
      margin: 0 50px;
      padding: 0;
      list-style: none;
      display: flex;
      @media (max-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
      }
      li {
        display: inline-block;
        position: relative;
        margin-right: 5px;
        margin-left: 5px;
        width: calc(33% - 12px);
        padding: 10px 30px 10px 0;
        cursor: pointer;
        a {
          color: black;
          cursor: pointer;
          @media (max-width: 768px) {
            width: 70%;
          }
          &:hover {
            text-decoration: none;
          }
          &:focus {
            text-decoration: none;
          }
          &:after {
            content: " ";
            background: url('{{file_dest}}/locationaftermap.svg') no-repeat;
            position: absolute;
            right: 0;
            top: 10px;
            width: 20px;
            height: 20px;
            display: block;
          }
        }
        &.active {
          &:before {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 10%;
            border-left: 10px solid transparent;
            border-bottom: 10px solid $brand-primary;
            border-right: 10px solid transparent;
          }
        }
      }
      &.upper {
        li {
          border-top: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      &.lower {
        li {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }
      &.listactive {
        li {
          padding-bottom: 30px;
          @media (max-width: 768px) {
            padding-bottom: 10px;
          }
        }
      }
    }
    .mapaccordioncontent {
      overflow: hidden;
      height:0;
      .row {
        background-color: $brand-primary;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 20px 0;
      }
      .left {
        padding: 55px;
        @media (max-width: 768px) {
          padding: 25px 15px;
        }
        h3 {
          padding-top: 0;
          &:before {
            background-color: transparent;
          }
        }
        .localdescription {
          margin-bottom: 0px;
        }
      }
      .right {

      }
    }
  }
}