
  .ui-helper-hidden-accessible {
    display: none;
  }
  .ui-menu {
    background-color: #fff;
    width: 200px;
    border: solid 1px #ccc;
  }
  .ui-menu,
  .ui-menu li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .ui-menu-item a {
    background-color: #fff;
    color: #222;
    display: block;
    padding: 4px 8px;
    cursor: pointer;
  }
  .ui-menu-item a:hover,
  .ui-menu-item a:focus {
    text-decoration: none;
  }
  .ui-menu-item a.ui-state-focus,
  .ui-menu-item a.ui-state-active {
    background-color: $brand-primary;
    color: #fff;

    .gha-cube & {
      background-color: $cube-main-color-green;
    }

    .gha-wlhp & {
      background-color: $wlhp-main-color-blue;
    }

    
  }
  .ui-menu li:first-child:before {
    content: 'Search suggestions';
    text-align: right;
    display: block;
    font-size: 16px;
    color: #7a7a7a;
    padding: 4px 8px;
  }
