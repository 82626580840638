/* Main content area general styles */

img {
  border-radius: 0px;
  margin-bottom: 1.75em;
}

// h1.landing-page {
//   font-size: 24px;
//   position: relative;
//   padding-top: 20px;
// }
.intro {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 0.75em;
  color: $brand-primary;
  max-width: 800px;  
  font-family: SinkinSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.introstories {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 0.75em;
  color: $brand-primary;
  max-width: 800px;
  font-family: FSMe, "Helvetica Neue", Helvetica, Arial, sans-serif;
  .loretto & {
    font-family: SinkinSans-Light, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}

.main-header {
  //border-bottom: 1px solid #ccc;
  //color: $font-grey;
  padding-bottom: 10px;
  margin-top: 0px;
  // padding-top: 33px;
  margin-top: 40px;
}

.main {
  .home & {
    padding-top:0;
  }
  background-color: #fff;
  padding-top: 1.32em;
  padding-bottom: 60px;
}


.download-link {
  background: url('{{file_dest}}/download-link.svg') no-repeat 4px 1px / 12px;
  padding-left: 21px;
  //display: block;
  font-weight: 200;
  // height: 17px;

  .text-muted {
    font-weight: lighter;
  }
}

.external-link {
  background: url('{{file_dest}}/external-link.svg') no-repeat 4px 2px / 15px;
  padding-left: 27px;
  display: block;
  font-weight: 200;
  // height: 17px;
}

.internal-link {
  background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 8px 2px / 6px;
  padding-left: 27px;
  display: block;
  font-weight: 200;
}

.list-arrow {

  ul & {
    padding-left: 11px;
  }

  list-style: none;
  padding-left: 1px;

  li {
    font-weight: 200;
    background: url('{{file_dest}}/bullet-arrow.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
  }
}

.list-bubble {
  list-style: none;
  padding-left: 1px;

  li {
    font-weight: 200;
    background: url('{{file_dest}}/bullet-bobble.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
    margin-bottom: 0px !important;
  }
}

.list-arrow_outside {
  ul & {

  }
  margin-top: 0px;
  padding-left: 0px;
  list-style: none;

  li {
    font-weight: 200;
    background: url('{{file_dest}}/bullet-bobble.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
    line-height: 1.5;
    background:none;
    list-style-type:none;
    padding-left:5px;

    &:last-child {
      margin-bottom: 1.5em;
    }
  }
}

.list-bubble_outside {
  margin-top: -9px;
  padding-left: 0px;
  list-style: none;

  li {
    font-weight: 200;
    background: url('{{file_dest}}/bullet-bobble.svg') no-repeat 0 2px / 6px;
    padding-left: 17px;
    line-height: 1.5;

    &:last-child {
      margin-bottom: 1.5em;
    }
  }
}
ul{
  &.list-bubble_inside {
    margin-top: -9px;
    padding-left: 0px;
    list-style: none;

    li {
      font-weight: 200;
      background: url('{{file_dest}}/bullet-bobble.svg') no-repeat 0 2px / 6px;
      padding-left: 17px;
      line-height: 1.5;
      &:before {
        content: " ";
      }

      &:last-child {
        margin-bottom: 1.5em;
      }
    }
  }
}



.button {
  font-size: 18px;
  font-weight: 700;
  background-color: $brand-primary;
  color: #fff;
  cursor: pointer;
  padding: 0.5em  2.5em;
  position: relative;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 22px;

  &:hover,
  &:focus {
    color: #fff;
    background-color: $brand-primary;
    text-decoration: underline !important;
  }
  &--green{
    background-color: $brand-primary-first;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $brand-primary-first;
      text-decoration: underline !important;
    }
  }
}


/* Links */
.link-arrow {
  padding-left: 0.875em;
  position: relative;
  display: block;

  &:before {
    content: ">";
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

/* Backgrounds */
.background-digonal-stripes {
    height: 215px;
    width: 1020px;
    background: linear-gradient(
      -45deg,
      transparent 80px,
      transparent 160px,
      #f2f2f2 160px,
      #f2f2f2 240px,
      transparent 240px,
      transparent 320px,
      #f2f2f2 320px,
      #f2f2f2 400px,
      transparent 400px,
      transparent 480px,
      #f2f2f2 480px,
      #f2f2f2 560px,
      transparent 560px,
      transparent 640px,
      #f2f2f2 640px,
      #f2f2f2 720px,
      transparent 720px,
      transparent 800px
    ), linear-gradient(
      -135deg,
      transparent 0,
      transparent 8px,
      #eeeeee 8px,
      #eeeeee 88px,
      transparent 88px,
      transparent 168px,
      #eeeeee 168px,
      #eeeeee 248px,
      transparent 248px,
      transparent 328px,
      #eeeeee 328px,
      #eeeeee 408px,
      transparent 408px,
      transparent 488px,
      #eeeeee 488px,
      #eeeeee 568px,
      transparent 568px,
      transparent 648px
    ) ;
}

.icon-facebook {
  background: url('{{file_dest}}/icon-facebook.png') no-repeat;
  background: url('{{file_dest}}/icon-facebook.svg') no-repeat;
  background-size: contain;
}

.icon-twitter {
  background: url('{{file_dest}}/icon-twitter.png') no-repeat;
  background: url('{{file_dest}}/icon-twitter.svg') no-repeat;
  background-size: contain;
}

.icon-info {
  background: url('{{file_dest}}/icon-info.png') no-repeat;
  background-size: contain;
}

.icon-arrow-left {
  background: url('{{file_dest}}/icon-arrow-left.png') no-repeat;
  background-size: contain;
}

.icon-arrow-right {
  background: url('{{file_dest}}/icon-arrow-right.png') no-repeat;
  background-size: contain;
}

.clearfix {
  clear: both;
}
.homepage {
  padding-left: 5px !important;
  padding-right: 5px !important;

  @media (max-width: 767px) {
   padding-left: 15px !important;
   padding-right: 15px !important;
  }
}

.homepagetiles {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-bottom: 8px !important;
  @media (max-width: 768px) {
    padding-bottom: 0 !important;
  }
}

.nogutter {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &:before {
    content: none;
  }

  &:after {
    content: '';
  }
}

//.row.is-flex > [class*='col-'] {
//  display: -webkit-box;
//  display: -webkit-flex;
//  display: -ms-flexbox;
//  display: flex;
//  -webkit-box-orient: vertical;
//  -webkit-box-direction: normal;
//  -webkit-flex-direction: column;
//  -ms-flex-direction: column;
//  flex-direction: column;
//}

.news-listing img {
  border-radius: 2px 2px 0 0;
  margin: 0;
  width: 100%;
}
.news-listing .text {
  background-color: #f2f2f2;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0 0 2px 2px;
  height: 245px;
}

.news-listing h3 {
  font-size: 16px;
  margin-top: 0;
  font-weight: 600;
  line-height: 26px;
  min-height: 52px;
}

.news-listing hr {
  border-top: 1px solid #bfbfbf;
  margin-top: 12px;
  margin-bottom: 5px;
}

.img-wrapper-news {
  position: relative;
  padding-bottom: 49.3%;
  height: 0;
  overflow: hidden;
}

.news-listing.newswide .text {
  background-color: #FCEEED;
}

.news-listing.newswide hr {
  border-top: 1px solid #F6C0B8;
  margin-top: 12px;
  margin-bottom: 5px;
}

select::-ms-expand {
  display: none;
}

.resmarg {
  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
}

.sr-only {
  color: $black;
}

.search-contextual-navigation {
  h3 {
    &:before {
      display: none;
    }
  }
}