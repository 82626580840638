/* Module: S */
.search-content {
  .sub-header__search{
    border-top: 1px solid #ccc;
    font-size: 22px;
    font-weight: 200;
    color: #737373;
    margin: 0.8em 0 0.7em 0;
    padding-top: 0.7em;
  }

  .accordion {
    background-color: #f8f8f8;
    border-bottom: 3px solid #e4e4e4;
    margin-bottom: 1em;
    padding-bottom: 0;

    h2 {
      background-color: #e4e4e4;
      color: $gray-darker;
      font-size:  18px;
      padding: 1em calc(3% + 25px) 1em 3%;
      margin: 0;
      cursor: pointer;
      position: relative;

      .sq-open,
      .sq-close {
        min-width: 20px;
        position: absolute;
        right: 3%;
        top: 50%;
        margin-top: -10px;
      }

      .sq-close {
        background: url('mysource_files/expand.png') no-repeat 50% 50%;
      }
      .sq-open {
        background: url('mysource_files/collapse.png') no-repeat 50% 50%;
      }
    }

    > .row {
      padding: 0 3%;
      margin-top: 1em;
    }

    h3 {
      //font-size: 20px;
      margin: 0 0 1em 0;
      //font-weight: lighter;
      //color: #222;
      margin-bottom: 1.5em;
      font-size: 16px;
      font-weight: 500;
      color: #666;
    }

    li {
      margin-bottom: 1em;

      > ul {
        margin-top: 1em;
      }
    }
  }
}