/* Gets bootstrap variables for media size */
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";

// Base font
$base-font-scale: 1;  // The font scale multiplier (e.g. 2 = 32px/2em/2rem)
$base-font-pixel: 16; // Used for font size calculations & conversions
$base-font-colour: #222;

// Selection highlighting
$content-selection-highlight: #b3d4fc;

// Custom
$black:             #000    !default;
$white:             #fff    !default;

//Dimensions
$laptop:1200;
$tablet:768;
$phone:320;

//$font-grey: #7a7a7a;
$font-grey: #727272;
$font-grey-dark: #262626;
$medium-grey: #efefef;
$dark-red: #9b0000;
$brand-primary:     #00847B; // GHA bright red
$brand-primary-first:     #00A499; // loretto bright green
$brand-primary-second:     #7CE0D3; // loretto iris blue
$footer-muted:      #777777;
$footer-bg-1:       #DDDDDD;
$footer-bg-2:       #EEEEEE;
$light-bg: #e1f3f5;
$light-border: #a8e4e8;
$gray: #231f20;
$bright-gray: #7a7a7a;
//$greyborders: #959499;

// gha Cube
$cube-main-color-green: #008675;
$cube-main-color-red: #a50034;
$cube-light-bg: #f8ebef;
$cube-light-border: #e0a6b8;
$cube-light-responsive: #339e91;

// gha wlhp
$wlhp-main-color-blue: #004c97;
$wlhp-main-color-purple: #af1685;
$wlhp-light-bg: #f8ebef;
$wlhp-light-border: #e3aed4;
$wlhp-light-responsive: #1e5d9e;


// Dunedin
$dunedin-main-color-blue: #002f6c;
$dunedin-main-color-green: #205c40;
$dunedin-main-color-yellow: #ffc72c;
$dunedin-light-bg: #EBF8F2;
$dunedin-light-border: #607c6f; //#87D5B1;
$dunedin-main-color-blue-lighter: #395F91;
$dunedin-main-color-blue-lightest: #6D8EB9;
$dunedin-light-responsive: #2D558A;

// Loretto
$loretto-main-color-blue: #00A9E0;
$loretto-main-color-darkblue: #007096; //
$loretto-light-bg: #edf9fd; //#EBF4F8;
$loretto-light-border: #7fb7ca; //#A7CCE0;
$loretto-main-color-blue-lighter: #c3dfe9; //#75B4C9;
$loretto-main-color-blue-lightest: #7fd4ef; //#BCD6DF;
$loretto-light-responsive: #2082a3; //#b2d4df; //#5FB0CB;